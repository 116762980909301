import React, { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import useAuth from "./../../Hooks/useAuth";
import { StyledPaymentForm } from "./styles";
import { useLocalStorage, getStoredValue } from "../../Hooks/useLocalStorage";
import CheckoutForm from "./Checkout";
import useAxiosSecured from "../../Hooks/useAxiosSecured";
const { REACT_APP_STRIPE_PUB_KEY } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PUB_KEY);
function StripePayment() {
  const [value, updateValue] = useLocalStorage("payment", {
    clientSecret: "",
  });
  const [secrete, setSecrete] = useState();
  const { auth } = useAuth();
  const axiosSecured = useAxiosSecured();

  useEffect(() => {
    (async () => {
      try {
        const response = await axiosSecured.post("/create-payment-intent", {
          items: value,
        });
        const data = response.data;

        updateValue({
          clientSecret: data.payload.clientSecret,
        });

        setSecrete(data.payload.clientSecret);
      } catch (error) {}
    })();
  }, []);

  const appearance = {
    theme: "flat",
    variables: {
      borderRadius: "6px",
    },
  };
  const options = {
    clientSecret: secrete,
    appearance,
  };

  return (
    <StyledPaymentForm>
      {secrete && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm secret={secrete} />
        </Elements>
      )}
    </StyledPaymentForm>
  );
}

export default StripePayment;
