import "./styles.css";
import { useEffect, useState } from "react";
import { initialTabs as tabs } from "./list";
import { motion, AnimatePresence } from "framer-motion";
import MyGallery from "./Gallery";
import hairGalleryData from "../../Assets/data/galleryData/hairGalleryData.json";
import bodySculptingGalleryData from "../../Assets/data/galleryData/bodySculpting.json";
import facialsGalleryData from "../../Assets/data/galleryData/facialsGalleryData.json";
import makeupGalleryData from "../../Assets/data/galleryData/makeupGalleryData.json";
import browGalleryData from "../../Assets/data/galleryData/browGalleryData.json";
import teethWhiteningData from "../../Assets/data/galleryData/teethWhitening.json";
// import filterStyle from "./styles.css"
export default function GalleryFilter() {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    setSelected(
      selectedTab.label === "Hair"
        ? hairGalleryData
        : selectedTab.label === "Facials"
        ? facialsGalleryData
        : selectedTab.label === "Body Sculpting"
        ? bodySculptingGalleryData
        : selectedTab.label === "Brow"
        ? browGalleryData
        : selectedTab.label === "Teeth Whitening"
        ? teethWhiteningData
        : selectedTab.label === "Makeup"
        ? makeupGalleryData
        : hairGalleryData
    );
  }, [selectedTab]);

  return (
    <div className="filter__window">
      <nav className="filter__nav">
        <ul className="ul__wrapper">
          {tabs.map((item) => (
            <li
              key={item.label}
              className={`${
                item === selectedTab ? "selected" : ""
              }  li__wrapper`}
              onClick={() => setSelectedTab(item)}
            >
              {` ${item.label}`}
              {item === selectedTab ? (
                <motion.div className="underline" layoutId="underline" />
              ) : null}
            </li>
          ))}
        </ul>
      </nav>
      <main className="filter__main">
        <AnimatePresence mode="wait">
          <motion.div
            key={selectedTab ? selectedTab.label : "empty"}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <MyGallery data={selected} />
          </motion.div>
        </AnimatePresence>
      </main>
    </div>
  );
}
