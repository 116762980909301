import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import subDays from "date-fns/subDays";
import axios from "axios";
import {
  StyledDatepicker,
  StyledSelectInput,
  StyledTimeContainer,
  StyledPlaceHolder,
  StyledTimeParentContainer,
  StyledTimeParentContainer2,
} from "./style/calender.style";
import "./style/calender.css";
import "react-datepicker/dist/react-datepicker.css";
import { useContext } from "react";
import { TwContext } from "../../Context/Booking/TwCalenderContextProvider";
import { axiosInstance, axiosSecured } from "../../Services/axiosInstance";
// import { CalenderDateModel } from '../../../../server/model/calendarModel';
import { useNavigate } from "react-router-dom";
import { useLocalStorage, getStoredValue } from "../../Hooks/useLocalStorage";
import useAuth from "../../Hooks/useAuth";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { SelectDurationContext } from "../../Context/Booking/SelectedDurationContext";
import { selectBox, checkAMorPM } from "../../Utils/helpers";
import { useSnackbar } from "notistack";
import Loader from "../LoadingScreen/CircleLoader";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  .inner__cont {
    // border: 1px solid blue;
    display: flex;
    width: 100%;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    .calender {
      text-align: center;
    }
  }
  .time__container-selected {
    background-color: #dec6c6;
  }
`;
const StyledButton = styled(Button)`
  margin-left: auto;
  background-color: red;
  color: #fff;
  width: 100px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &:hover {
    background-color: red;
    color: #fff;
  }
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const StyledInnerContainer1 = styled.div`
  width: 80%;
  display: flex;
  padding: 0 1rem;
  // border: 1px solid blue;
  justify-content: space-around;
  align-self: center;
  @media screen and (max-width: 580px) {
    text-align: center;
    flex-direction: column;
    padding: 0;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
const TwCalender = () => {
  const { getCalender, getInputState } = useContext(TwContext);
  const [isSelcted, setIsSelcted] = useState(false);
  const [disableInput, setDisableInput] = getInputState;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [btnState, setBtnState] = useState(false);
  const [availableTime, setAvailableTime] = useState([]);
  const [calender, setCalender] = getCalender;
  const [updatedDate, setUpdateDate] = useState();
  // stores date range from the present day
  const [startDate, setStartDate] = useState(new Date());
  // stores selected date
  const [selectedDate, setSelectedDate] = useState([]);
  // stores disabled dates
  const [disabledDates, setDisabledDates] = useState([]);
  // stores selected time
  const [selectedTime, setSelectedTime] = useState(null);
  // temporary holder for disabled
  const [value, updateValue] = useLocalStorage("selectedDuration", {
    selectedDate: [],
    selectedTime: null,
    servicePath: "",
  });
  const [payInfo, updatePayInfo] = useLocalStorage("payment", {
    appointmentDate: null,
    appointmentTime: null,
    servicePath: "",
    // amount: "",
  });
  const [saveRoute, setSaveRoute] = useLocalStorage("saveRoute", {
    serviceRoute: "",
  });

  const [loader, setLoader] = useState(false);

  const { auth } = useAuth();

  let getDisabledDate = [];

  const datePickerRef = React.useRef(null);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (datePickerRef && datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (calender && calender.payload) {
      calender.payload.forEach((item) => {
        getDisabledDate.push(subDays(new Date(item.selectedDate), 0));
      });

      setDisabledDates(getDisabledDate);
    }
  }, [calender.payload]);

  const handleChange = (date) => {
    setStartDate(date);
    setSelectedDate({
      dateId: date.toDateString(),
      serviceName: "Teeth_whitening",
      slots: new Date(date).getDay() === 0 ? 6 : 14,
      entries: 1,
      day: new Date(date).getDate(),
      selectedDate: new Date(date),
      selectedTime: new Date(date).getTime(),
      time:
        new Date(date).getDay() === 0
          ? [
              { time: "12:00", slot: 2, entries: 0, isActive: true },
              { time: "1:30", slot: 2, entries: 0, isActive: true },
              { time: "2:30", slot: 2, entries: 0, isActive: true },
            ]
          : [
              { time: "8:30", slot: 2, entries: 0, isActive: true },
              { time: "10:00", slot: 2, entries: 0, isActive: true },
              { time: "11:30", slot: 2, entries: 0, isActive: true },
              { time: "1:00", slot: 2, entries: 0, isActive: true },
              { time: "2:30", slot: 2, entries: 0, isActive: true },
              { time: "4:00", slot: 2, entries: 0, isActive: true },
              { time: "5:30", slot: 2, entries: 0, isActive: true },
            ],
      isActive: true,
    });

    (async function () {
      setLoader(true);
      const response = await axiosInstance.post(
        "/fetch/teeth-whitening/calender-time",
        {
          dateId: date.toDateString(),
        }
      );
      if (response.data.payload) {
        setLoader(false);

        setAvailableTime(response.data.payload.time);
        setDisableInput({ ...disableInput, select: false });
      } else {
        setLoader(true);

        // this makes request if date is not found  in the database
        const response = await axiosSecured.post("/teeth-whitening/calender", {
          dateId: date.toDateString(),
          serviceName: "Teeth_whitening",
          slots: new Date(date).getDay() === 0 ? 6 : 14,
          entries: 0,
          day: new Date(date).getDate(),
          selectedDate: new Date(date),
          selectedTime: selectedDate.selectedTime,
          time:
            new Date(date).getDay() === 0
              ? [
                  { time: "12:00", slot: 2, entries: 0, isActive: true },
                  { time: "1:30", slot: 2, entries: 0, isActive: true },
                  { time: "2:30", slot: 2, entries: 0, isActive: true },
                ]
              : [
                  { time: "8:30", slot: 2, entries: 0, isActive: true },
                  { time: "10:00", slot: 2, entries: 0, isActive: true },
                  { time: "11:30", slot: 2, entries: 0, isActive: true },
                  { time: "1:00", slot: 2, entries: 0, isActive: true },
                  { time: "2:30", slot: 2, entries: 0, isActive: true },
                  { time: "4:00", slot: 2, entries: 0, isActive: true },
                  { time: "5:30", slot: 2, entries: 0, isActive: true },
                ],
          isActive: true,
        });

        if (response.success) {
          setLoader(false);
        }
        setAvailableTime(response.data.payload.time);
        setDisableInput({ ...disableInput, select: false });
      }
    })();
  };

  const handleSelectTime = async (event) => {
    let time = event.target.textContent.split(" ")[0].replace(/(AM|PM)/i, "");
    setSelectedTime(time);

    setSelectedDate({
      ...selectedDate,
      selectedTime: time,
    });

    setDisableInput({ ...disableInput, button: false });

    setSelectedDate((prevState) => ({
      ...prevState,
      time: prevState.time.map((timeObj) => {
        if (timeObj.time === time) {
          return { ...timeObj, entries: timeObj.entries + 1 };
        } else {
          return timeObj;
        }
      }),
    }));
    setBtnState(true);

    setDisableInput({ ...disableInput, button: false });
  };

  const handleSend = async () => {
    updateValue({
      selectedDate: selectedDate.dateId,
      selectedTime: selectedDate.selectedTime,
      selectedDateInfo: selectedDate,

      servicePath: "teeth-whitening",
    });

    // updatePayInfo({
    //   appointmentDate: selectedDate.dateId,
    //   appointmentTime: selectedDate.selectedTime,
    //   amount: parseFloat(payInfo.amount) + 0,
    // });
    setSaveRoute({
      serviceRoute: window.location.pathname,
    });

    setTimeout(() => {
      if (Object.keys(auth).length !== 0) {
        if (auth.role === 0) {
          navigate("/payment-options");
        } else {
          navigate("/error401");
        }
      } else {
        navigate("/login");
      }
    }, 200);
  };

  const handleClick = async () => {
    try {
      if (selectedTime) {
        await handleSend();
      }
    } catch (error) {
      enqueueSnackbar("Sorry, Something went wrong.Please try again", {
        variant: "error",
      });
    }
  };
  return (
    <StyledContainer>
      <StyledInnerContainer1>
        <div className="static-datepicker">
          <StyledDatepicker
            minDate={new Date()}
            open
            inline
            popperPlacement="bottom-start"
            excludeDates={[new Date(), ...disabledDates]}
            dateFormat="MMMM d, yyyy"
            selected={isSelcted ? startDate : ""}
            onChange={(date) => handleChange(date)}
            placeholderText="Select day"
          />

          <br />
        </div>

        {availableTime.length > 0 ? (
          <>
            <StyledTimeParentContainer>
              {" "}
              {availableTime.map((time, idx) => {
                return (
                  <StyledTimeContainer
                    id="myDiv"
                    className="time__container"
                    onClick={(event) => {
                      selectBox(event, idx);

                      handleSelectTime(event);
                    }}
                    key={idx}
                  >
                    {time.time ? time.time : ""}
                    {checkAMorPM(time.time)}
                  </StyledTimeContainer>
                );
              })}
            </StyledTimeParentContainer>
          </>
        ) : (
          <StyledPlaceHolder>
            {loader ? (
              <StyledTimeParentContainer2>
                <Loader />
              </StyledTimeParentContainer2>
            ) : (
              <h3>Please select a date</h3>
            )}
          </StyledPlaceHolder>
        )}
      </StyledInnerContainer1>
      {selectedTime && (
        <StyledButton onClick={handleClick} style={{ color: "#fff" }}>
          Continue
        </StyledButton>
      )}
    </StyledContainer>
  );
};
export default TwCalender;
