import { useEffect, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
// =============================|| MODULES ||=======================================

import danritlogo from "../../Assets/Images/Icons/danlogo.svg";
import danritLogoDark from "../../Assets/Images/Icons/danlogo.svg";
import html2pdf from "html2pdf.js";
import QRCode from "react-qr-code";
import "./style.css";

import useAuth from "../../Hooks/useAuth";
import useAxiosSecured from "../../Hooks/useAxiosSecured";
import { getStoredValue, useLocalStorage } from "../../Hooks/useLocalStorage";
import { axiosInstance } from "../../Services/axiosInstance";
import {
  checkAMorPM,
  getBalance,
  commitmentAmt,
  formatPhoneNumber,
} from "../../Utils/helpers";

const Receipt = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();

  const { auth } = useAuth();
  const [customerDetails, setCustomerDetails] = useState({});
  const axiosSecured = useAxiosSecured();
  const navigate = useNavigate();
  const [value, updateValue] = useLocalStorage("payment", {
    appointmentId: null,
  });
  const [selectedTimeValue, updateSelectedTimeValue] = useLocalStorage(
    "selectedDuration",
    {
      servicePath: "",
      selectedDate: [],
      selectedTime: null,
    }
  );
  const [qrCodeValue, setQrcodeValue] = useState({});
  const { selectedDate, selectedTime } = selectedTimeValue;
  const clearLocalStorageProperty = (propertyKey) => {
    localStorage.removeItem(propertyKey);
  };

  const handleSend = async () => {
    try {
      console.log("handle send");
      let updated = null;

      if (selectedDate && selectedTime) {
        const response = await axiosInstance.post(
          `/${selectedTimeValue.servicePath}/calender`,
          selectedTimeValue.selectedDateInfo
        );
        const data = response.data;
        if (data.success) {
          // first check
          (() => {
            const datetime = new Date(
              `${selectedTimeValue.selectedDate} ${selectedTimeValue.selectedTime}`.replace(
                /(AM|PM)/i,
                ""
              )
            );

            const formattedDate = datetime.toISOString();

            const data = {
              appointmentDate: formattedDate,
              commitmentFee: commitmentAmt(value.amount),
              serviceName: value.item,
              appointmentTime: selectedTimeValue.selectedTime,
              serviceType: value.serviceType,
            };
            axiosSecured
              .post("/customer/create/appointment", data)
              .then((response) => {
                const data = response.data;
                if (data.success) {
                  const { appointmentId } = data.payload;
                  updateValue({
                    appointmentId: appointmentId,
                  });

                  setQrcodeValue({
                    "Appointment ID": data.payload.appointmentId,
                    "Customer Email": data.payload.customerEmail,

                    "Appointment Date":
                      selectedTimeValue.selectedTime +
                      " " +
                      checkAMorPM(selectedTimeValue.selectedTime) +
                      " EST",
                  });
                }

                Swal.fire({
                  title: "Sent!",
                  icon: "success",
                  backdrop: "static",
                  allowOutsideClick: false,
                  confirmButtonColor: "#9de0f6",
                  confirmButtonText: "OK",
                  focusCancel: false,
                  focusConfirm: false,
                  text: `We acknowledge your appointment and kindly request you to download your receipt. Thank you for choosing DanRit. `,
                });
              })
              .catch((error) => {
                if (
                  error.response &&
                  error.response.status >= 400 &&
                  error.response.status < 500
                ) {
                  const message = error.response.data?.payload;

                  enqueueSnackbar(message, { variant: "error" });
                } else if (error.request) {
                  enqueueSnackbar(
                    "Unable to connect to the server. Please check your internet connection and try again.",
                    { variant: "error" }
                  );
                } else {
                  enqueueSnackbar(
                    "Unable to process request.Please contact support",
                    { variant: "error" }
                  );
                }
              });
          })();
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Failure!",
        icon: "error",
        backdrop: "static",
        allowOutsideClick: false,
        confirmButtonColor: "#9de0f6",
        confirmButtonText: "OK",
        focusCancel: false,
        focusConfirm: false,
        text: `Apologies, we are unable to register your appointment at the moment. Please contact us immediately for assistance`,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("log");
        }
      });
    }
  };

  const handleDownload = () => {
    const element = document.querySelector(".receipt-container");

    html2pdf().from(element).save("receipt.pdf");

    clearLocalStorageProperty("payment");
    clearLocalStorageProperty("selectedDuration");
    setTimeout(() => {
      navigate("/");
    }, 300);
  };

  useEffect(() => {
    (async () => {
      await handleSend();
    })();
  }, []);

  useEffect(() => {
    axiosSecured
      .get("/fetch/profile")
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setCustomerDetails({
            firstName: data.payload.firstName,
            lastName: data.payload.lastName,
            phone: data.payload.phone,
            email: data.payload.email,
          });
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          const message = error.response.data?.payload;

          enqueueSnackbar(message, { variant: "warning" });
        } else if (error.request) {
          enqueueSnackbar(
            "Unable to connect to the server. Please check your internet connection and try again.",
            { variant: "error" }
          );
        } else {
          enqueueSnackbar("Sorry, something went wrong.Please try again", {
            variant: "error",
          });
        }
      });
  }, [auth]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    const retrievePaymentIntent = async () => {
      try {
        const { paymentIntent } = await stripe.retrievePaymentIntent(
          clientSecret
        );
        switch (paymentIntent.status) {
          case "succeeded":
            (async () => {
              const { paymentIntent } = await stripe.retrievePaymentIntent(
                value.clientSecret
              );

              const payload = {
                email: auth.email,
                paymentDate: new Date(),
                amount: paymentIntent.amount,
                status: paymentIntent.status,
                paymentId: paymentIntent.id,
                currency: paymentIntent.currency,
                clientSecret: paymentIntent.client_secret,
                serviceName: value.item,
                paymentMethod: value.paymentMethod,
              };
              const response = await axiosSecured.post(
                "/save/customer/payment-info",
                payload
              );
              // TODO: send mail
            })();

            break;

          default:
            // TODO: handle this
            break;
        }
      } catch (error) {
        Swal.fire({
          title: "Payment Failure!",
          icon: "error",
          backdrop: "static",
          allowOutsideClick: false,
          confirmButtonColor: "#9de0f6",
          confirmButtonText: "OK",
          focusCancel: false,
          focusConfirm: false,
          text: `Unable to complete payment`,
        });
        console.log(error);
      }
    };

    retrievePaymentIntent();
  }, [stripe]);

  return (
    <div className="my-10">
      <div className="receipt-container relative max-w-[600px] mt-10 rounded mx-auto p-4 font-poppins grid place-items-center border border-gray-500">
        <div className=" text-black relative p-2 border border-smallBorder shadow-sm h-fit">
          <div className="mx-auto">
            <img
              src={danritLogoDark}
              height={100}
              width={100}
              alt="logo"
              className="mx-auto"
            />
          </div>
          <div className="flex justify-between flex-row-reverse items-center mt-10 px-3">
            {/* QR CODE GENERATOR */}
            <div style={{ height: "auto", maxWidth: 64, width: "100%" }}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                // add value here
                value={JSON.stringify(qrCodeValue)}
                viewBox={`0 0 256 256`}
              />
            </div>
            <div className="">
              <h2 className="font-bold text-lg text-paymentReceipt">
                Payment Receipt
              </h2>
              <p className="text-receipt-date-sm md:text-receipt-date-lg text-left">
                Generated on {moment(new Date()).format("YYYY-MM-DD")}
              </p>
            </div>
          </div>

          <div className="bg-hero bg-no-repeat w-full h-full bg-center bg-contain">
            <table className="table-fixed mt-10 w-full mx-auto border-0">
              <thead>
                <tr>
                  <th
                    className="p-1 md:p-2 font-bold col-span-6  bg-red-200  text-3x2 text-left indent-4"
                    colspan="6"
                  >
                    CUSTOMER'S INFORMATION
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-white border-t-4">
                  <th
                    className="text-receipt-date-sm md:text-receipt-details border-r-4 border-white w-5  text-left p-2 md:p-3 my-1 bg-receiptTableGreen "
                    colSpan="2"
                  >
                    Name
                  </th>
                  <td
                    className="text-receipt-date-sm md:text-receipt-details my-1 px-2 bg-receiptTableGreen "
                    colspan="4"
                  >
                    {customerDetails.firstName} {customerDetails.lastName}
                  </td>
                </tr>
                <tr className="border-y-4 border-white">
                  <th
                    className="text-receipt-date-sm md:text-receipt-details border-r-4 w-5 border-white  text-left p-2 md:p-3 my-1 bg-receiptTableGreen "
                    colSpan="2"
                  >
                    Email
                  </th>
                  <td
                    className="text-receipt-date-sm md:text-receipt-details my-1 px-2 bg-receiptTableGreen "
                    colspan="4"
                  >
                    {customerDetails.email}
                  </td>
                </tr>
                <tr className="border-y-4 border-white">
                  <th
                    className="text-receipt-date-sm md:text-receipt-details border-r-4 w-5 border-white  text-left p-2 md:p-3 my-1 bg-receiptTableGreen "
                    colSpan="2"
                  >
                    Phone
                  </th>
                  <td
                    className="text-receipt-date-sm md:text-receipt-details my-1 px-2 bg-receiptTableGreen "
                    colspan="4"
                  >
                    {"+1 " + formatPhoneNumber(customerDetails.phone)}
                  </td>
                </tr>
                <tr className="border-y-4 border-white">
                  <th
                    className="text-receipt-date-sm md:text-receipt-details border-r-4 w-5 border-white  text-left p-2 md:p-3 my-1 bg-receiptTableGreen "
                    colSpan="2"
                  >
                    Appointment Date
                  </th>
                  <td
                    className="text-receipt-date-sm md:text-receipt-details my-1 px-2 bg-receiptTableGreen "
                    colspan="4"
                  >
                    {selectedTimeValue.selectedDate}
                  </td>
                </tr>
                <tr className="border-y-4 border-white">
                  <th
                    className="text-receipt-date-sm md:text-receipt-details border-r-4 w-5 border-white  text-left p-2 md:p-3 my-1 bg-receiptTableGreen "
                    colSpan="2"
                  >
                    Appointment Time
                  </th>
                  <td
                    className="text-receipt-date-sm md:text-receipt-details my-1 px-2 bg-receiptTableGreen "
                    colspan="4"
                  >
                    {selectedTimeValue.selectedTime + " "}{" "}
                    {checkAMorPM(selectedTimeValue.selectedTime)}{" "}
                    <span style={{ marginLeft: "0.5rem" }}>(EST)</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="p-1 md:p-2 border font-bold  bg-red-200  w-full text-3x2 text-left indent-4">
              PAYMENT DETAILS
            </p>

            <table className="table-fixed bg-no-repeat mt-1 w-full mx-auto border-0">
              <thead>
                <tr>
                  <th
                    className="border-b-4 border-white text-receipt-date-sm md:text-receipt-details text-3x2 text-left p-1 md:p-3 my-1 bg-receiptTableGreen "
                    colSpan="2"
                  >
                    Payment Date
                  </th>
                  <th
                    className="border-b-4 border-white text-receipt-date-sm md:text-receipt-details text-3x2 text-left p-1 md:p-3 my-1 bg-receiptTableGreen "
                    colSpan="3"
                  >
                    Service Description
                  </th>
                  <th
                    className="border-b-4 border-white text-receipt-date-sm md:text-receipt-details text-3x2 text-left p-1 md:p-3 my-1 bg-receiptTableGreen "
                    colSpan="1"
                  >
                    Starting Price
                  </th>
                  <th
                    className="border-b-4 border-white text-receipt-date-sm md:text-receipt-details text-3x2 text-left p-1 md:p-3 my-1 bg-receiptTableGreen "
                    colSpan="1"
                  >
                    Paid
                  </th>
                  <th
                    className="border-b-4 border-white text-receipt-date-sm md:text-receipt-details text-3x2 text-left p-1 md:p-3 my-1 bg-receiptTableGreen "
                    colSpan="1"
                  >
                    Balance
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border border-b-4 border-white">
                  <td
                    className=" text-receipt-date-sm md:text-receipt-details my-1 bg-receiptTableGreen text-lgx1 p-1 md:p-3"
                    colspan="2"
                  >
                    {moment(new Date()).format("YYYY-MM-DD")}
                  </td>
                  <td
                    className=" text-receipt-date-sm md:text-receipt-details my-1 bg-receiptTableGreen text-lgx1 p-1 md:p-3"
                    colspan="3"
                  >
                    <p style={{ fontWeight: 500 }}> {value.item}</p>
                    <p> {value.info}</p>
                  </td>
                  <td
                    className=" text-receipt-date-sm md:text-receipt-details my-1 bg-receiptTableGreen  p-1 md:p-3 text-lgx1"
                    colspan="1"
                  >
                    $
                    {parseInt(getStoredValue("payment").startingPrice).toFixed(
                      2
                    )}
                  </td>
                  <td
                    className=" text-receipt-date-sm md:text-receipt-details my-1 bg-receiptTableGreen  p-1 md:p-3 text-lgx1"
                    colspan="1"
                  >
                    ${commitmentAmt(parseInt(value.amount)).toFixed(2)}
                  </td>
                  <td
                    className=" text-receipt-date-sm md:text-receipt-details my-1 bg-receiptTableGreen text-lgx1 p-1 md:p-3"
                    colspan="1"
                  >
                    {/* $
                    {getBalance(
                      commitmentAmt(parseInt(value.amount)).toFixed(1),
                      parseInt(value.amount)
                    )} */}
                    Payment is to be completed subsequent to the delivery of
                    service.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="receipt-button md:w-[60%] mx-auto px-5  md:px-15 flex flex-row justify-center items-center my-8">
        <button
          style={{ marginLeft: "auto" }}
          className="text-white py-2 px-3 text-center mx-auto rounded-md bg-downloadReceipt shadow-lg uppercase text-sm"
          onClick={handleDownload}
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default Receipt;
