import styled from "@emotion/styled";
import { Button, InputLabel, OutlinedInput } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
export const StyledContainer = styled.div`
  border-radius: 10px;
  display: flex;
  background-color: #fff;
  flex-flow: row wrap;
  padding-top: 2rem;
  padding: 5rem 2rem;
  /* width: 80%; */
  // min-height: calc(100vh - 300px);
  // height: 100vh;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  // margin: 2rem auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;

  @media (min-width: 768px) {
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1024px) {
    width: 43%;
  }
`;

export const StyledButton = styled(Button)`
  background: #fb464c;

  &:hover {
    background: #ff0000;
    color: #fff;
  }
`;
export const StyledInputField = muiStyled(OutlinedInput)(({ theme }) => ({
  "&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
    borderColor: "#8b7d7d", // set a custom color here
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#e2dede",
  },
  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grey[400],
  },
  "& .MuiInputLabel-root": {
    color: "#000", // set the label color here
  },
  "&.Mui-focused .MuiInputLabel-root": {
    color: "#000", // set the label color here
  },
}));

export const StyledInputLabel = muiStyled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  "&.Mui-focused": {
    color: "#4f5672",
  },
}));

export const StyledLogoSpace = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledFormHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
