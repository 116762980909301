export function selectBox(event, boxId) {
  const clickedBox = event.target;
  const boxes = document.querySelectorAll(".time__container");

  boxes.forEach((box) => {
    if (box.id !== boxId) {
      box.classList.remove("time__container-selected");
    }
  });

  clickedBox.classList.add("time__container-selected");
}

export function checkAMorPM(time) {
  const [hours, minutes] = time.split(":");

  const parsedHours = parseInt(hours, 10);
  if (parsedHours >= 8 && parsedHours < 12) {
    return "AM";
  } else {
    return "PM";
  }
}

export const commitmentAmt = (value) => {
  const value2 = 300;
  const tenPercent = value2 * 0.1;

  return tenPercent;
};
export const getBalance = (percentage, price) => {
  // const value = 200;
  //   console.log(price - percentage);
  return price - percentage;
};

export const generateUniqueId = () => {
  const timestamp = new Date().getTime();
  const randomNum = Math.floor(Math.random() * 10000);
  return `${timestamp}_${randomNum}`;
};

export function formatPhoneNumber(phoneNumber = "0000000000") {
  const cleaned = phoneNumber.replace(/\D/g, "");

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
}

export const extractAmount = (inputString) => {
  const regex = /\$\d+/;

  const match = inputString.match(regex);

  if (match) {
    const extractedValue = match[0];
    return extractedValue;
  } else {
    return "";
  }
};
export function stripeCharge() {
  return 1.2;
}
