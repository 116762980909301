import React from "react";
import styles from "./Error500Page.module.css";

function Error500Page({ message }) {
  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <img src="https://i.imgur.com/qIufhof.png" />

        <h1>
          <span>500</span> <br />
          Internal server error
        </h1>
        <p></p>
        <p className={styles.info}>
          We apologize for the inconvenience caused by the error that occurred
          during the processing of your request. Please be informed that our
          technical team is diligently working to resolve the issue.
          <br />
          <a href="/" target="_blank" className={styles.home__text}>
            Home
          </a>
        </p>
      </div>
    </div>
  );
}

export default Error500Page;
