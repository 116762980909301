import React, { useEffect, useState } from "react";
import { MenuItems } from "./MenuItems";
import style from "./Dropdown.module.css";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
const StyledDivider = styled.div`
  border: 1px solid rgba(159, 159, 159, 0.3);
  height: 0.5px;
  width: 93%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: aut0;
`;
function Dropdown({ toggle }) {
  const [click, setClick] = useState(false);
  const navLinks = React.useRef();

  useEffect(() => {
    if (click) {
      navLinks.current.style.display = "none";
    }
  }, [click]);

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        ref={navLinks}
        className={
          click ? style["dropdown-menu clicked"] : style["dropdown-menu"]
        }
      >
        {MenuItems.map((item, index) => {
          return (
            <>
              <li className={style.dropdown__list} key={index}>
                <Link
                  className={style[item.cName]}
                  to={item.path}
                  onClick={() => setClick(false)}
                >
                  {item.title}
                </Link>
              </li>
              {index !== 5 && <StyledDivider />}
            </>
          );
        })}
      </ul>
    </>
  );
}

export default Dropdown;
