import React from "react";
import "./style.scss";
function ListPlaceholder() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-12">
          <div className="chart">
            <div className="no-chart-data">
              <h2 className="title">No data found</h2>
              {/* <div className="y-axis"></div> */}
              <div className="x-axis"></div>
              <div className="lines">
                {/* <div className="overlay"></div> */}
                <div className="line line-0">
                  <div className="slope"></div>
                </div>
                <div className="line line-1"></div>
                <div className="line line-2"></div>
                <div className="line line-3"></div>
                <div className="line line-4"></div>
                <div className="line line-5"></div>
                {/* <div className="line line-6"></div> */}
                <div className="line line-7"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListPlaceholder;
