import { Backdrop, CircularProgress, Typography } from "@mui/material";
import styled from "@emotion/styled";
const StyledTypography = styled(Typography)`
  && {
    margin-left: 10px;
    cursor: pointer;
    margin-top: 3rem;
    text-align: center;
  }
`;
const CustomBackdrop = styled(Backdrop)`
  && {
    background-color: rgba(
      0,
      0,
      0,
      0.8
    ); /* Set the desired opacity value here */
  }
`;

function SimpleBackdrop({ handleOpen, handleClose, open, message }) {
  // throw new Error("somethin went wrong");

  return (
    <div>
      {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
      <CustomBackdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        open={open}
      >
        <CircularProgress color="success" />
        <StyledTypography variant="h6" component="h3">
          {message}
        </StyledTypography>
      </CustomBackdrop>
    </div>
  );
}

export default SimpleBackdrop;
