import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalPayment from "./PayPalPayment";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { SnackbarProvider, useSnackbar } from "notistack";
import { checkAMorPM } from "../../Utils/helpers";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import backgroundImage from "../../Assets/Images/page_bg_img.jpg";
const { REACT_APP_PAYPAL_CLIENT_ID } = process.env;

const StyledButtonContainer = styled.div`
  .property {
    font-weight: 600;
  }
  .value2 {
    text-transform: lowercase;
  }
  .value {
    font-weight: 400;
  }
`;
const StyledPaypalContainer = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-size: cover;
  // margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  p {
    margin-bottom: 1rem;
  }
`;
const PayWithPayPal = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [value, updateValue] = useLocalStorage("payment", {
    appointmentId: null,
  });
  const initialOptions = {
    "client-id": REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };

  const [item, setItem] = useState([]);
  const [amount, setAmount] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState([]);
  const [appointmentTime, setAppointmentTime] = useState([]);
  const [information, setInformation] = useState([]);
  const [selectedTimeValue, updateSelectedTimeValue] = useLocalStorage(
    "selectedDuration",
    {
      selectedDate: [],
      selectedTime: null,
    }
  );
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("payment"));
    if (items) {
      const amountToBePaid = 30;
      const itemPaidFor = items.item;
      const Date = items.appointmentDate;
      const Time = items.appointmentTime;
      const info = items.info;

      setAmount(amountToBePaid.toString());
      setItem(itemPaidFor);
      setAppointmentDate(Date);
      setAppointmentTime(Time);
      setInformation(info);
    }
  }, []);

  return (
    <StyledPaypalContainer>
      <PayPalScriptProvider options={initialOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 80px)",
          }}
        >
          <StyledButtonContainer
            style={{
              backgroundColor: "#fff",
              opacity: 0.8,
              borderRadius: "5px",
              width: "380px",
              padding: " 1rem ",
              fontWeight: 300,
              height: "320px",
            }}
          >
            <div
              style={{
                marginBottom: "1.5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  Payment Summary
                </h3>
              </div>
              <br />
              <p>
                <span className="property">Commitment Fee</span> :{" "}
                <span className="value">
                  {" "}
                  ${amount} @{value.info.split("\n")[1]}
                </span>
              </p>
              <p>
                <span className="property">Service</span> :{" "}
                <span className="value"> {item} </span>
              </p>
              <p>
                <span className="property">Appointment Date</span> :{" "}
                <span className="value"> {appointmentDate} </span>
              </p>
              <p>
                <span className="property">Appointment Time </span> :{" "}
                <span className="value">
                  {" "}
                  {appointmentTime}{" "}
                  {checkAMorPM(selectedTimeValue.selectedTime)} (EST)
                </span>
              </p>
              {/* <p>
            <span className="property">Purchase Information </span> :{" "}
            <span className="value2">{information} </span>
          </p> */}
            </div>

            <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
              <PayPalPayment />
            </SnackbarProvider>
          </StyledButtonContainer>
        </div>
      </PayPalScriptProvider>
    </StyledPaypalContainer>
  );
};

export default PayWithPayPal;
