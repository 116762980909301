import { useEffect, createContext, useState } from "react";
import { axiosInstance } from "../../Services/axiosInstance";

export const BrowContext = createContext();
export function BrowContextProvider({ children }) {
  const [calender, setCalender] = useState([]);
  const [disableInput, setDisableInput] = useState({
    button: true,
    select: true,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    let isCancelled = false;
    setLoading(true);
    axiosInstance
      .get("/fetch/brow/calender-dates")
      .then((data) => {
        if (!isCancelled) {
          setCalender(data);

          setLoading(false);
          setError("");
        }
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);

        setCalender([]);
      });

    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <BrowContext.Provider
      value={{
        getCalender: [calender, setCalender],
        getInputState: [disableInput, setDisableInput],
      }}
    >
      {children}
    </BrowContext.Provider>
  );
}
