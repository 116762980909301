import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { blue } from "@mui/material/colors";
import { FiInstagram } from "react-icons/fi";
import { BsFacebook } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import styled from "@emotion/styled";
import logo from "../../Assets/Images/Icons/danlogo.svg";
import { AiFillHome } from "react-icons/ai";
import { SiMinutemailer } from "react-icons/si";
const socialLinks = [
  {
    icon: <FiInstagram />,
    link: "https://www.instagram.com/danritbeautysalon/",
    info: "Instagram",
  },
  {
    icon: <BsFacebook />,
    link: "https://web.facebook.com/Danritbeautysalon",
    info: "Facebook",
  },

  {
    icon: <FaTiktok />,
    link: "https://www.tiktok.com/@danritbeautysalon",
    info: "TikTok",
  },
  {
    icon: <SiMinutemailer />,
    link: "/contact",
    info: "Contact Us",
  },
];

const StyledDialog = styled(Dialog)`
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 600px;
    padding: 0 1rem;
  }
`;

function QrCodeDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
    window.open(value, "_blank");
  };

  return (
    <StyledDialog onClose={handleClose} open={open}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "0.5rem",
        }}
      >
        <img
          src={logo}
          width="70px"
          height="7px"
          style={{ textAlign: "center" }}
          alt="logo"
        />
      </div>
      <DialogTitle
        sx={{
          fontFamily: "'myHeadFont',san-serif",
          textAlign: "center",
          fontSize: "2rem",
        }}
      >
        Our Social handles
      </DialogTitle>
      <List sx={{ pt: 0 }}>
        {socialLinks.map((item, idx) => {
          return (
            <ListItem disableGutters key={idx}>
              <ListItemButton
                onClick={() => handleListItemClick(item.link)}
                key={idx}
                sx={{
                  border: "1px solid  rgba(255, 56, 56,0.2)",
                  borderRadius: "10px",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{ bgcolor: "rgba(255, 56, 56,0.2)", color: "#FF3838 " }}
                  >
                    {item.icon}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText sx={{ fontSize: "1.5rem" }} primary={item.info} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </StyledDialog>
  );
}

QrCodeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default QrCodeDialog;
// export default function QrCodeDialog() {
//   const [open, setOpen] = React.useState(false);
//   const [selectedValue, setSelectedValue] = React.useState(emails[1]);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = (value) => {
//     setOpen(false);
//     setSelectedValue(value);
//   };

//   return (
//     <div>
//       <Typography variant="subtitle1" component="div">
//         Selected: {selectedValue}
//       </Typography>
//       <br />
//       <Button variant="outlined" onClick={handleClickOpen}>
//         Open simple dialog
//       </Button>
//       <SimpleDialog
//         selectedValue={selectedValue}
//         open={open}
//         onClose={handleClose}
//       />
//     </div>
//   );
// }
