import "./gallery.css";
import React, { useEffect } from "react";
import Fancybox from "./Fancybox.jsx";

const MyGallery = ({ data }) => {
  return (
    <Fancybox
      options={{
        Carousel: {
          infinite: false,
        },
      }}
    >
      <main className="main__gallery_cont" id="danrit--gallery">
        <div className="gallery__container">
          {data.map((item, idx) => {
            return (
              <div className="gallery__card" key={idx}>
                <div className="gallery__card-image">
                  <a
                    href={item.imageUrl}
                    data-fancybox={item.dataName}
                    data-caption={item.dataCaption}
                  >
                    <img
                      src={process.env.PUBLIC_URL + item.imageUrl}
                      alt={item.imageAlt}
                    />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </main>
    </Fancybox>
  );
};

export default MyGallery;
