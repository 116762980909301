import React, { useState } from "react";
// import { useSelector } from 'react-redux';
import styles from "./Login.module.css";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import Swal from "sweetalert2"; // project imports
import { AiOutlineCopyright, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md";
import styled from "@emotion/styled";
import { styled as muiStyled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
// component imports

import useScriptRef from "../../Hooks/useScriptRef";
import AnimateButton from "../../Components/extended/AnimateButton";
import Google from "../../Assets/Images/Icons/googleSocial.svg";
import { axiosInstance } from "../../Services/axiosInstance";
import useAuth from "../../Hooks/useAuth";
// import ErrorBoundary from "../../Components/ErrorBoundary/ErrorBoundary";
// import Error500Page from "../../Components/ErrorBoundary/Errors/Error500/Error500Page";
import useRefreshToken from "../../Hooks/useRefreshToken";
import SimpleBackdrop from "../../Components/LoadingScreen/BackDrop";
import { Link } from "react-router-dom";
import danRitLogo from "../../Assets/Images/Icons/danrit_logo.png";
import danRitMainLogo from "../../Assets/Images/Icons/danlogo.svg";
import loginBGD from "../../Assets/Images/login_bg_desktop.png";
import { Helmet } from "react-helmet-async";
import { getStoredValue } from "../../Hooks/useLocalStorage";
const { REACT_APP_DOMAIN_NAME } = process.env;
// ============================||  LOGIN ||============================ //

const StyledButton = styled(Button)`
  background: #fb464c;
  &:hover {
    background: #ff0000;
    color: #fff;
  }
`;
export const StyledLogoContainer = styled.div`
  width: 70px;
  height: 70px;
  // border: 1px solid blue;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  color: #fff;
`;

const StyledInputField = muiStyled(OutlinedInput)(({ theme }) => ({
  "&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
    borderColor: "#8b7d7d", // set a custom color here
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#e2dede",
  },
  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grey[400],
  },
  "& .MuiInputLabel-root": {
    color: "#000", // set the label color here
  },
  "&.Mui-focused .MuiInputLabel-root": {
    color: "#000", // set the label color here
  },
}));

const StyledLinks = styled.div`
  width: 100%;
  // border: 1px solid red;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-around;
  .link {
    text-decoration: underline;
    color: #8c93a2;
  }
`;

const StyledInputLabel = muiStyled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  "&.Mui-focused": {
    color: "#4f5672",
  },
}));

const Login = ({ ...others }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const { setAuth, persist, setPersist } = useAuth();
  const scriptedRef = useScriptRef();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [checked, setChecked] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isScreenLarge = useMediaQuery("(min-height:1000px)");

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    localStorage.setItem("persist", true);
  }, [persist]);
  const handleSubmit = (values) => {
    const { email, password } = values;
    const data = {
      email,
      password,
    };
    handleOpen();
    axiosInstance
      .post("/login", data)
      .then((response) => {
        handleClose();
        if (response.data.success) {
          const { role, is_verified, _id, email, firstName, lastName } =
            response.data.payload;
          const accessToken = response.data.payload.accessToken;
          const refreshToken = response.data.payload.newRefreshToken;

          // Cookies.set("jwt", accessToken);

          Cookies.set("jwt", accessToken, {
            secure: true,
            // sameSite: "Strict",
            expires: 24 * 60 * 60 * 1000,
            // maxAge: 24 * 60 * 60 * 1000,
            httpOnly: true,
          });
          setAuth({
            role,
            is_verified,
            accessToken,
            _id,
            email,
            firstName,
            lastName,
          });
          setPersist(true);
          // localStorage.setItem("persist", true);
          if (!is_verified)
            return Swal.fire({
              title: "Oops!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#9de0f6",
              cancelButtonColor: "#fb464c",
              focusConfirm: false,
              inputAutoFocus: false,
              backdrop: "static",
              allowOutsideClick: false,
              confirmButtonText: "Confirm Email",
              focusCancel: false,
              text: "Sorry for the inconvenience, but your account needs verification. Please click 'Confirm Email' to proceed.",
            }).then((result) => {
              if (result.isConfirmed) {
                handleOpen();
                axiosInstance
                  .post("/email-verification", { _id })
                  .then((response) => {
                    const data = response.data;
                    handleClose();

                    if (data.success) {
                      Swal.fire({
                        title: "Sent!",
                        icon: "success",
                        backdrop: "static",
                        allowOutsideClick: false,
                        confirmButtonColor: "#9de0f6",
                        confirmButtonText: "OK",
                        focusCancel: false,
                        focusConfirm: false,
                        text: `An email verification message has been dispatched to the email ${email}`,
                      });
                    } else {
                      enqueueSnackbar(
                        "Sorry, Something went wrong.Please try again",
                        { variant: "error" }
                      );
                    }
                  })
                  .catch((error) => {
                    handleClose();

                    enqueueSnackbar(
                      "Sorry, we are unable to connect to your email.Please try again",
                      { variant: "error" }
                    );
                  });
              }
            });
          if (role === 3 || role === 1) return navigate("/admin/dashboard/app");

          if (role === 0 && is_verified) {
            if (getStoredValue("saveRoute")) {
              if (getStoredValue("saveRoute").serviceRoute)
                return navigate(getStoredValue("saveRoute").serviceRoute);
            } else {
              return navigate("/");
            }
          }
        }
      })
      .catch((error) => {
        handleClose();
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          const message = error.response.data?.payload;

          enqueueSnackbar(message, { variant: "error" });
        } else if (error.request) {
          enqueueSnackbar(
            "Unable to connect to the server. Please check your internet connection and try again.",
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(
            "Sorry, we are unable to connect to your email. Please try again",
            { variant: "error" }
          );
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Login - DanRit Beauty Salon</title>

        <meta
          name="description"
          content="Log in to your DanRit Beauty Salon account to access your profile, appointments, and exclusive member benefits. Welcome back!"
        />

        <meta
          name="keywords"
          content="login, sign in, salon member login, user account, member benefits, beauty community"
        />

        <meta name="author" content="DanRit Beauty Salon" />

        <link rel="canonical" href="https://www.danritbeautysalon.com/login" />

        <meta property="og:title" content="Login - DanRit Beauty Salon" />
        <meta
          property="og:description"
          content="Log in to your DanRit Beauty Salon account to access your profile, appointments, and exclusive member benefits. Welcome back!"
        />
        <meta
          property="og:image"
          content="https://www.danritbeautysalon.com/static/media/danlogo.6bc6d612f55000b935b03dcae73a441a.svg"
        />
        <meta
          property="og:url"
          content="https://www.danritbeautysalon.com/login"
        />
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '532804699038962');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
          
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt="DanRit"
            src="https://www.facebook.com/tr?id=532804699038962&ev=PageView&noscript=1"
          />
          `}
        </noscript>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-EJ8VF2YNW0"
        ></script>

        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-EJ8VF2YNW0');
            `}
        </script>
      </Helmet>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${loginBGD})`,
        }}
      >
        <SimpleBackdrop
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
          message="Please wait while we process your request. This may take a moment"
        />
        <div className={styles.loginContainer}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <div style={{ width: "100%" }}>
                <StyledLogoContainer>
                  <img src={danRitMainLogo} alt="Logo" />
                </StyledLogoContainer>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />

                <Button
                  variant="outlined"
                  sx={{
                    cursor: "unset",
                    m: 2,
                    py: 0.5,
                    px: 7,
                    borderColor: `${theme.palette.grey[100]} !important`,
                    color: `${theme.palette.grey[900]}!important`,
                    fontWeight: 500,
                    borderRadius: `${20}px`,
                  }}
                  disableRipple
                  disabled
                >
                  <Typography variant="h5"> SIGN IN</Typography>
                </Button>

                <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <Typography variant="subtitle1">
                  Sign in with Email address
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Formik
            initialValues={{
              email: "",
              password: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Please Enter a valid email.eg: example@gmail.com")
                .max(255)
                .required("Email is required"),
              password: Yup.string()
                .max(255)
                .matches(
                  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                  {
                    message:
                      "Password Must Contain Minimum eight characters, at least one upper case,one lower case letter , one digit and  one special character. example:Password12#",
                  }
                )
                .required("Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                if (scriptedRef.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
                handleSubmit(values);
              } catch (err) {
                if (scriptedRef.current) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...others}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <StyledInputLabel htmlFor="outlined-adornment-email-login">
                    Email Address
                  </StyledInputLabel>
                  <StyledInputField
                    id="outlined-adornment-email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Email Address "
                    style={{ marginBottom: "2rem" }}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <StyledInputLabel htmlFor="outlined-adornment-password-login">
                    Password
                  </StyledInputLabel>
                  <StyledInputField
                    id="outlined-adornment-password-login"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <MdOutlineVisibility />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    inputProps={{}}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-login"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    sx={{ textDecoration: "none", cursor: "pointer" }}
                    onClick={async () => {
                      const { value: email } = await Swal.fire({
                        input: "email",
                        text: "Email Confirmation",
                        confirmButtonColor: "#9de0f6",
                        confirmButtonText: "Send",
                        inputAutoFocus: false,
                        backdrop: "static",
                        allowOutsideClick: false,
                        inputPlaceholder: "Type your email here...",
                        focusCancel: false,
                        focusConfirm: false,
                        inputAttributes: {
                          "aria-label": "Type your email here",
                        },
                        showCancelButton: true,
                      });
                      if (email) {
                        handleOpen();
                        axiosInstance
                          .post(
                            "/reset/password",

                            {
                              email,
                            },
                            { timeout: 50000 }
                          )

                          .then((response) => {
                            handleClose();
                            if (response.status === 200) {
                              Swal.fire({
                                title: "Sent!",
                                icon: "success",
                                backdrop: "static",
                                allowOutsideClick: false,
                                confirmButtonColor: "#9de0f6",
                                confirmButtonText: "OK",
                                focusCancel: false,
                                focusConfirm: false,
                                text: `A password reset email has been sent to your registered email address.`,
                              });
                            }
                          })
                          .catch((error) => {
                            handleClose();
                            const status = error.response?.status;
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              backdrop: "static",
                              allowOutsideClick: false,
                              confirmButtonColor: "#9de0f6",
                              focusCancel: false,
                              focusConfirm: false,
                              text:
                                status !== 404
                                  ? "We encountered an error. Please try again"
                                  : error.response?.data.payload,
                            });
                          });
                      }
                    }}
                  >
                    Forgot Password?
                  </Typography>
                </Stack>
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box sx={{ mt: 2 }}>
                  <AnimateButton>
                    <StyledButton
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      // onClick={handleLogin}
                    >
                      Sign in
                    </StyledButton>
                  </AnimateButton>
                </Box>

                <Box alignSelf="center" mt={1}>
                  <Typography variant="subtitle1">
                    Don't Have an account?{" "}
                    <Link
                      style={{ color: "blue", textDecoration: "underline" }}
                      to="/register"
                    >
                      Sign up
                    </Link>
                  </Typography>
                </Box>
                <Box alignSelf="center" mt={1}>
                  <StyledLinks>
                    <div>
                      <Link
                        className="link"
                        style={{ display: "flex", alignItems: "center" }}
                        to="/"
                      >
                        <AiOutlineCopyright /> DanRit
                      </Link>
                    </div>

                    <Link
                      className="link"
                      // to={`${REACT_APP_DOMAIN_NAME}/contact`}
                      to="/contact"
                    >
                      Contact
                    </Link>
                    <Link
                      className="link"
                      // to={`${REACT_APP_DOMAIN_NAME}/privacy-policy`}
                      to="/privacy-policy"
                    >
                      Privacy & Policy
                    </Link>
                  </StyledLinks>
                </Box>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default Login;
