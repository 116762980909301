import { useState } from "react";
import { alpha } from "@mui/material/styles";
import {
  Box,
  Stack,
  Popover,
  Divider,
  MenuItem,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import Swal from "sweetalert2";
import useAuth from "../../Hooks/useAuth";
import useLogout from "../../Hooks/useLogout";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import {
  NavLink as RouterLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { BsPerson, BsPersonCheck } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";
import accountIcon from "../../Assets/Images/Icons/profileIcon.svg";
export default function AccountPopover({ fontSize }) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { auth } = useAuth();
  const location = useLocation();

  const isCustomerRoute = location.pathname.startsWith("/customer/dashboard");
  // const isCustomerProfileRoute = location.pathname.startsWith("/customer/dashboard/profile");

  const MENU_OPTIONS = [
    {
      label: `${!auth ? "Home" : "Dashboard"}`,
      icon: <MdOutlineSpaceDashboard color="rgb(212 162 163)" />,
      path: `${
        auth.role === 3
          ? "/admin/dashboard/app"
          : auth.role === 1
          ? "/admin/dashboard/app"
          : "/customer/dashboard"
      }`,
    },
    {
      label: "Profile",
      icon: <BsPerson color="rgb(212 162 163)" />,
      path: "/customer/dashboard/profile",
    },
  ];
  const logOut = useLogout();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleLogOut = () => {
    setOpen(null);

    Swal.fire({
      title: "Confirmation",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#9de0f6",
      cancelButtonColor: "#fb464c",
      focusConfirm: false,
      inputAutoFocus: false,
      backdrop: "static",
      allowOutsideClick: false,
      confirmButtonText: "Confirm",
      focusCancel: false,
      text: `Are you sure you want to logout?`,
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          await logOut();
          navigate("/");
          setOpen(null);
        } else {
          setOpen(null);
        }
      })
      .catch((error) => {
        enqueueSnackbar("Sorry, Something went wrong. Please try again", {
          variant: "error",
        });
      });
  };
  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        disableRipple={isCustomerRoute ? true : false}
        onClick={!isCustomerRoute ? handleOpen : null}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.1),
            },
          }),
        }}
      >
        {" "}
        {isCustomerRoute ? (
          <div
            style={{
              borderRadius: "50%",
              fontWeight: "900",
              fontSize: "1.1rem",
              color: "#FC2F32",
              fontFamily: "serif",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>{ auth?.firstName.charAt(0) + " " + " " + auth?.lastName.charAt(0) }</span>
            
          </div>
        ) : (
          <div
            style={{
              width: "45px",
              height: "45px",
              background: "#eeeaea",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              fontWeight: "900",
              fontSize: "1.2rem",
              color: "#04297A",
              fontFamily: "serif",
            }}
          >
            <span>
              {/* {auth?.firstName.charAt(0) + "" + auth?.lastName.charAt(0)} */}
              {/* <BsPersonCheck
                fontSize={fontSize ? fontSize : 35}
                color="#fb464c"
                fontWeight={900}
              /> */}
              <AccountIcon />
            </span>
            {/* span */}
          </div>
        )}
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            // background: "red",
            boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {auth?.firstName + " " + auth?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {auth?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={handleClose}
              component={RouterLink}
              to={option.path}
            >
              <span style={{ marginRight: "1rem", fontSize: "1.4rem" }}>
                {option.icon}
              </span>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogOut} sx={{ m: 1 }}>
          <span style={{ marginRight: "1rem", fontSize: "1.4rem" }}>
            <BiLogOut color="rgb(212 162 163)" />
          </span>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}

const AccountIcon = () => {
  return (
    <div style={{ width: "50px", height: "50px" }}>
      <img src={accountIcon} alt="icon" />
    </div>
  );
};
