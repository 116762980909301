import React, { useState, useEffect } from "react";
import teethWhiteningData from "../../Assets/data/teeth.json";
import { motion } from "framer-motion";
import Footer from "../Footer/Footer";
import Booking from "./Booking";
import { TwContextProvider } from "../../Context/Booking/TwCalenderContextProvider";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import ServiceDetails from "../ServiceDetails/ServiceDetails";
import useAuth from "./../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import FixedBG from "../FixedBG/FixedBG";
import styled from "@emotion/styled";
import { extractAmount } from "../../Utils/helpers";
import { Helmet } from "react-helmet-async";
const StyledCurve = styled.div`
  width: 100%;

  height: 50px;
  background: #f2f5f9;
  .curve {
    width: 100%;
    height: 50px;

    // background-color: #f2f5f9;
    background-color: #131b20;
    width: 100%;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
  }
  @media screen and (max-width: 960px) {
    height: 20px;
    .curve {
      height: 20px;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
    }
  }
`;
const StyledImage = styled.img`
  width: 160px;
  border-radius: 50% !important;
  height: 160px;
  margin: 0 auto;
  display: flex;
  align-self: center;
`;
const TeethWhitening = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubServiceIndex, setSelectedSubServiceIndex] = useState(null);
  const [showBooking, setShowBooking] = useState(false);
  const [hideOtherSubServices, setHideOtherSubServices] = useState(false); // New state for hiding other sub-services
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const [value, updateValue] = useLocalStorage("payment", {
    item: "",
    info: "",
    amount: null,
  });

  function addAmountToLocalStorage(subService, amount) {
    updateValue({
      info: `${subService.info}\n ${subService.duration}`,
      item: subService.name,
      amount: amount.toString(),
      serviceType: "Teeth Whitening",
      startingPrice: amount.toString(),
    });
  }

  const handleOption1Change = (subService, option) => {
    setIsChecked1(!isChecked1);
    if (!isChecked1) {
      let sumAmt =
        parseFloat(value.amount) +
        parseFloat(extractAmount(subService[option]).split("$")[1]);

      addAmountToLocalStorage(subService, sumAmt);
    } else {
      let sumAmt =
        parseFloat(value.amount) -
        parseFloat(extractAmount(subService[option]).split("$")[1]);

      addAmountToLocalStorage(subService, sumAmt);
    }
  };

  // Function to handle checkbox 2 change
  const handleOption2Change = (subService, option) => {
    setIsChecked2(!isChecked2);
    if (!isChecked2) {
      let sumAmt =
        parseFloat(value.amount) +
        parseFloat(extractAmount(subService[option]).split("$")[1]);
      addAmountToLocalStorage(subService, sumAmt);
    } else {
      let sumAmt =
        parseFloat(value.amount) -
        parseFloat(extractAmount(subService[option]).split("$")[1]);

      addAmountToLocalStorage(subService, sumAmt);
    }
    // handleCheckboxChange(subService, option);
  };

  const handleBookingToggle = (index) => {
    if (selectedSubServiceIndex === index && showBooking) {
      setSelectedSubServiceIndex(null);
      setShowBooking(false);
      setHideOtherSubServices(false); // Unhide other sub-services
    } else {
      setSelectedSubServiceIndex(index);
      setShowBooking(true);
      setHideOtherSubServices(true); // Hide other sub-services
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Teeth Whitening Services - DanRit Beauty Salon</title>
        <meta
          name="description"
          content="Experience professional teeth whitening services to brighten your smile at DanRit Beauty Salon."
        />

        <meta
          name="keywords"
          content="teeth whitening, professional teeth whitening, teeth bleaching, teeth whitening treatments, smile enhancement, dental beauty services"
        />
        <meta
          name="keywords"
          content="Lip Treatment, Moisturizing Lip Balm, Sugar Lip Scrub, Smooth Lips, Soft Lips"
        />
        <meta
          name="keywords"
          content="Teeth Accessories, Decorative Teeth, Ornamental Teeth"
        />
        <meta
          name="keywords"
          content=" Organic Teeth Whitening, Natural Teeth Whitening, Brighten Teeth, Whiter Smile, Younger-looking Smile"
        />

        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '532804699038962');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
          
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt="DanRit"
            src="https://www.facebook.com/tr?id=532804699038962&ev=PageView&noscript=1"
          />
          `}
        </noscript>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-EJ8VF2YNW0"
        ></script>

        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-EJ8VF2YNW0');
            `}
        </script>
      </Helmet>

      <ServiceDetails
        serviceName={"Teeth Whitening Service"}
        pageLink={"/services"}
        pageName={"Services"}
        serviceText={
          "A radiant smile can significantly boost your confidence, and DanRit Beauty Salon offers professional teeth whitening services to help you achieve just that."
        }
        image={
          "https://images.pexels.com/photos/3845653/pexels-photo-3845653.jpeg"
        }
      />
      <FixedBG>
        <div className="pt-[50px]">
          <section className="max-w-[1200px] mx-auto px-5">
            <div className="flex flex-col">
              {teethWhiteningData.map((teethwhitening, index) => {
                const isCategorySelected =
                  selectedCategory === teethwhitening.category;
                const shouldRender = !selectedCategory || isCategorySelected;

                return (
                  shouldRender && (
                    <div key={index}>
                      {/* Render sub-services when category is selected */}
                      <div className="subservice-card md:w-[98%] mx-auto md:flex md:flex-col">
                        {teethwhitening.children.map((subService, index) => (
                          <motion.div
                            key={index}
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                              duration: 0.3,
                              delay: 0,
                              ease: "easeInOut",
                            }}
                            exit={{ opacity: 0, scale: 0 }}
                            className={`${
                              hideOtherSubServices &&
                              selectedSubServiceIndex !== index
                                ? "hidden"
                                : "subservice"
                            } flex flex-col my-5 sm:w-full justify-between rounded ${
                              selectedSubServiceIndex === index &&
                              hideOtherSubServices &&
                              "active"
                            }`}
                          >
                            <div className="sm:flex-row p-4 bg-white flex flex-col rounded">
                              {/* Sub-service image */}
                              <StyledImage
                                src={
                                  process.env.PUBLIC_URL + subService.cardImage
                                }
                                alt="subservice-img"
                                className=" mb-2 rounded"
                              />

                              <div className="flex flex-col sm:px-0 items-start md:ml-5 sm:w-full justify-around gap-3 mt-5 sm:mt-0">
                                {/* Sub-service title */}
                                <h2 className="text-[16px] capitalize  font-semibold sm:text-[18px] md:text-[22px]  text-black pl-2 w-full">
                                  {subService.name}
                                </h2>
                                {/* Sub-service information for big screens */}
                                <p className="pl-2 sm:text-base  sm:w-full">
                                  {subService.info}
                                </p>
                                {/* Sub-service duration */}
                                <p className="pl-2 w-full flex flex-row text-[12px] sm:text-base sm:pl-2 font-bold">
                                  {subService.duration}&nbsp;
                                  <span className="flex flex-row  font-bold">
                                    @ {subService.cost}
                                  </span>
                                </p>
                              </div>
                              <div className="flex flex-row mt-3 sm:mt-0 items-center px-2 justify-center sm:items-end md:justify-start">
                                {/* Book now button */}
                                <button
                                  className="bg-paymentReceipt md:hover:bg-[#FF0000] w-[50%] sm:w-[100px] border !block rounded text-white align-bottom  text-[10px] sm:text-[12px] py-2 px-2 sm:py-2 sm:px-4"
                                  onClick={() => {
                                    handleBookingToggle(index);
                                    updateValue({
                                      info: `${subService.info}\n ${subService.duration}`,
                                      item: subService.name,
                                      amount: subService.cost?.split("$")[1],
                                      serviceType: "Teeth Whitening",
                                      startingPrice: subService.cost
                                        ?.split("$")[1]
                                        .toString(),
                                    });
                                  }}
                                >
                                  {selectedSubServiceIndex === index &&
                                  hideOtherSubServices
                                    ? "Cancel"
                                    : "Book Now"}
                                </button>
                              </div>
                            </div>
                            {/* Render booking component */}
                            {selectedSubServiceIndex === index &&
                              hideOtherSubServices && (
                                <motion.div
                                  key={index}
                                  initial={{ opacity: 0, scale: 0.5 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                  transition={{
                                    duration: 0.3,
                                    delay: 0,
                                    ease: "easeInOut",
                                  }}
                                  exit={{ opacity: 0, scale: 0 }}
                                  className="booking-wrapper"
                                >
                                  <TwContextProvider>
                                    <div className="border-t border-gray-300 bg-white flex flex-wrap justify-around align-middle">
                                      {subService.option1 && (
                                        <label className="text-black flex p-4 justify-center align-middle gap-2">
                                          {subService.option1}
                                          <input
                                            type="checkbox"
                                            checked={isChecked1}
                                            onChange={() =>
                                              handleOption1Change(
                                                subService,
                                                "option1"
                                              )
                                            }
                                          />
                                        </label>
                                      )}
                                      {subService.option2 && (
                                        <label className="text-black flex p-4 justify-center align-middle gap-2">
                                          {subService.option2}
                                          <input
                                            type="checkbox"
                                            checked={isChecked2}
                                            onChange={() =>
                                              handleOption2Change(
                                                subService,
                                                "option2"
                                              )
                                            }
                                          />
                                        </label>
                                      )}
                                      {subService.medium && (
                                        <label className="text-black flex p-4 justify-center align-middle gap-2">
                                          {subService.medium}
                                          <input type="checkbox" />
                                        </label>
                                      )}
                                      {subService.small && (
                                        <label className="text-black flex p-4 justify-center align-middle gap-2">
                                          {subService.small}
                                          <input type="checkbox" />
                                        </label>
                                      )}
                                    </div>
                                    <Booking />
                                  </TwContextProvider>
                                </motion.div>
                              )}
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </section>
          <StyledCurve>
            <div className="curve"></div>
          </StyledCurve>
        </div>
      </FixedBG>
      <Footer />
    </>
  );
};

export default TeethWhitening;
