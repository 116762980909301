import React, { useState, useEffect } from "react";
import facials from "../../Assets/data/facial/facials.json";
import ServiceDetails from "../ServiceDetails/ServiceDetails";
import Footer from "../Footer/Footer";
import Booking from "./Booking";
import { motion } from "framer-motion";
import facialsImg from "../../Assets/Images/facialImg.jpeg";
import facial2 from "../../Assets/Images/facial2.jpeg";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import useAuth from "./../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { FaAngleLeft } from "react-icons/fa";
import FixedBG from "../FixedBG/FixedBG";
import { FacialsContextProvider } from "../../Context/Booking/FacialsCalenderContextProvider";
import { Helmet } from "react-helmet-async";
const StyledCurve = styled.div`
  width: 100%;

  height: 50px;
  // border: 1px solid blue;
  background: #f2f5f9;
  .curve {
    width: 100%;
    height: 50px;

    // background-color: #f2f5f9;
    background-color: #131b20;
    width: 100%;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
  }
  @media screen and (max-width: 960px) {
    height: 20px;
    .curve {
      // background-color: #000;

      height: 20px;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
    }
  }
`;
const StyledImg = styled.img`
  width: 60px;
  height: 60px;
`;
// const StyledImage = styled.img`
//   width: 160px;
//   border-radius: 50% !important;
//   height: 160px;
//   margin: 0 auto;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-align-self: center;
//   -ms-flex-item-align: center;
//   align-self: center;
//   // border: 1px solid red;
// `;
const StyledImage = styled.div`
  width: 160px;
  height: 160px;
  margin: 0 auto;
  display: flex;
  align-self: center;
  background-image: url("${(props) => props.image}");
  background-size: cover;
  background-position: top;
  border-radius: 50% !important;
`;
export const FacialService = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubServiceIndex, setSelectedSubServiceIndex] = useState(null);
  const [showBooking, setShowBooking] = useState(false);
  const [hideOtherSubServices, setHideOtherSubServices] = useState(false); // New state for hiding other sub-services
  const navigate = useNavigate();
  const { auth } = useAuth();
  // const [serviceDetails, setServiceDetails] = useState({
  //   item: "",
  //   info: "",
  //   price: null,
  // });
  const [value, updateValue] = useLocalStorage("payment", {
    item: "",
    info: "",
    amount: null,
  });
  const handleCategorySelect = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(category);
    }
    setSelectedSubServiceIndex(null);
    setShowBooking(false);
    setHideOtherSubServices(false);
  };

  const handleBookingToggle = (index) => {
    if (selectedSubServiceIndex === index && showBooking) {
      setSelectedSubServiceIndex(null);
      setShowBooking(false);
      setHideOtherSubServices(false); // Unhide other sub-services
    } else {
      setSelectedSubServiceIndex(index);
      setShowBooking(true);
      setHideOtherSubServices(true); // Hide other sub-services
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is loaded
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Facials Services - DanRit Beauty Salon</title>
        <meta
          name="description"
          content="Experience rejuvenating and professional facial services to enhance your skin's beauty at DanRit Beauty Salon."
        />

        <meta
          name="keywords"
          content="facials, facial treatments, skincare, spa facials, beauty salon services, skin rejuvenation, skincare routines"
        />
        <meta
          name="keywords"
          content="Deep cleansing facial, Gel manicure near me, Anti-aging beauty treatments"
        />
        <meta
          name="keywords"
          content="Neck Double Chin Treatment, Radio Frequency Treatment for Neck, Deluxe Double Chin Treatment"
        />
        <meta
          name="keywords"
          content="Swedish massage spa, Relaxation therapy, Spa day packages"
        />
        <meta
          name="keywords"
          content="Deep cleansing facial, Gel manicure near me, Anti-aging beauty treatments"
        />
        <meta
          name="keywords"
          content="Radio Frequency Facial, Deluxe Face and Neck Radio Frequency Facial"
        />

        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '532804699038962');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
          
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt="DanRit"
            src="https://www.facebook.com/tr?id=532804699038962&ev=PageView&noscript=1"
          />
          `}
        </noscript>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-EJ8VF2YNW0"
        ></script>

        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-EJ8VF2YNW0');
            `}
        </script>
      </Helmet>
      <ServiceDetails
        serviceName={"Facials Service"}
        pageLink={"/services"}
        pageName={"Services"}
        serviceText={
          "At DanRit Beauty Salon, indulge in a range of revitalizing facial treatments to nourish and restore your skin's radiance. Their experienced estheticians provide personalized facials tailored to your unique skin type and concerns."
        }
        image={
          "https://images.pexels.com/photos/6663456/pexels-photo-6663456.jpeg"
        }
      />
      <FixedBG>
        <div className="pt-[50px]">
          <section className="max-w-[1200px] mx-auto px-5">
            <div className="flex flex-col">
              <Button
                style={{ color: "#FC2F32" }}
                className="w-[fit-content] !block choose-category text-base items-start font-semibold text-paymentReceipt md:text-lg py-4 underline"
                onClick={() => handleCategorySelect(null)}
              >
                {selectedCategory ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaAngleLeft fontSize="1.3rem" /> View categories
                  </div>
                ) : (
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Choose a category
                  </span>
                )}
              </Button>
              {facials.map((facialData, index) => {
                const isCategorySelected =
                  selectedCategory === facialData.category;
                const shouldRender = !selectedCategory || isCategorySelected;

                return (
                  shouldRender && (
                    <div key={index}>
                      {/* Main category card */}
                      <div className="main-card shadow-sm bg-white flex-col flex sm:flex-row sm:justify-between items-center my-5  rounded py-3 sm:p-4">
                        <div className="flex flex-col sm:flex-row items-center w-full">
                          {/* Main category image */}
                          <StyledImg
                            src={process.env.PUBLIC_URL + facialData.mainImage}
                            alt="mainimages"
                            className="rounded-full bg-slate-300"
                          />

                          <div className="mx-8 category-card">
                            {/* Main category title */}
                            <h1 className="text-xs sm:text-xl text-black md:text-xl flex flex-row mb-4 sm:mb-0">
                              {facialData.category}
                            </h1>
                          </div>
                        </div>

                        {/* Button to toggle sub-services visibility */}
                        <button
                          id="select-btn"
                          className="bg-paymentReceipt mr-4 md:hover:bg-[#FF0000] !block border text-center rounded w-[50%] sm:w-[85px] text-white text-sm py-2 px-4"
                          onClick={() =>
                            handleCategorySelect(facialData.category)
                          }
                        >
                          {isCategorySelected ? "Hide" : "Select"}
                        </button>
                      </div>

                      {/* Render sub-services when category is selected */}
                      {selectedCategory === facialData.category && (
                        <div className="subservice-card md:w-[98%] mx-auto md:flex md:flex-col">
                          {facialData.children.map((subService, index) => (
                            <motion.div
                              key={index}
                              initial={{ opacity: 0, scale: 0.5 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{
                                duration: 0.3,
                                delay: 0,
                                ease: "easeInOut",
                              }}
                              exit={{ opacity: 0, scale: 0 }}
                              className={`${
                                hideOtherSubServices &&
                                selectedSubServiceIndex !== index
                                  ? "hidden"
                                  : "subservice"
                              } flex flex-col my-5 sm:w-full justify-between rounded ${
                                selectedSubServiceIndex === index &&
                                hideOtherSubServices &&
                                "active"
                              }`}
                            >
                              <div className="sm:flex-row p-4 bg-white flex flex-col rounded">
                                {/* Sub-service image */}
                                <div
                                  style={{
                                    minWidth: "200px",
                                    minHeight: "200px",
                                    // border: "1px solid blue",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <StyledImage
                                    role="img"
                                    aria-label={"facials image"}
                                    loading="lazy"
                                    image={
                                      process.env.PUBLIC_URL + subService.image
                                    }
                                    className=" mb-2 rounded w-100 h-300"
                                  />
                                </div>

                                <div className="flex flex-col items-start md:ml-5 sm:w-full justify-between ">
                                  {/* Sub-service title */}
                                  <h2 className="text-[16px]  font-semibold sm:text-[18px] md:text-[22px] text-black pl-2 w-full">
                                    {subService.name}
                                  </h2>
                                  {/* Sub-service information for big screens */}
                                  <p className="pl-2 sm:text-base sm:w-full">
                                    {subService.info}
                                  </p>
                                  {/* Sub-service duration */}
                                  <p className="pl-2 w-full flex flex-row text-[12px] sm:text-base sm:pl-2  font-bold">
                                    {subService.duration}&nbsp;
                                    <span className="flex flex-row ">
                                      @ {subService.price}
                                    </span>
                                  </p>
                                </div>
                                <div className="flex flex-row mt-3 sm:mt-0 items-center px-2 justify-center sm:items-end md:justify-start">
                                  {/* Book now button */}
                                  <button
                                    className="bg-paymentReceipt md:hover:bg-[#FF0000] w-[50%] sm:w-[100px] border !block rounded text-white align-bottom  text-[10px] sm:text-[12px] py-2 px-2 sm:py-2 sm:px-4"
                                    onClick={() => {
                                      handleBookingToggle(index);

                                      updateValue({
                                        info: `${subService.info}\n ${subService.duration}`,
                                        item: subService.name,
                                        amount: subService.price?.split("$")[1],
                                        serviceType: "Facials",
                                        startingPrice: subService.cost
                                          ?.split("$")[1]
                                          .toString(),
                                      });
                                    }}
                                  >
                                    {selectedSubServiceIndex === index &&
                                    hideOtherSubServices
                                      ? "Cancel"
                                      : "Book Now"}
                                  </button>
                                </div>
                              </div>
                              {/* Render booking component */}
                              {selectedSubServiceIndex === index &&
                                hideOtherSubServices && (
                                  <motion.div
                                    key={index}
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      duration: 0.3,
                                      delay: 0,
                                      ease: "easeInOut",
                                    }}
                                    exit={{ opacity: 0, scale: 0 }}
                                    className="booking-wrapper"
                                  >
                                    <FacialsContextProvider>
                                      <Booking />
                                    </FacialsContextProvider>
                                  </motion.div>
                                )}
                            </motion.div>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </section>
          <StyledCurve>
            <div className="curve"></div>
          </StyledCurve>
        </div>
      </FixedBG>
      <Footer />
    </>
  );
};
