import React, { useRef, useEffect, useState } from "react";

import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import "@fancyapps/ui/dist/carousel/carousel.css";
import HomeCSS from "./style.module.css";
import { motion, useAnimation } from "framer-motion";

import { Autoplay } from "@fancyapps/ui/dist/carousel/carousel.autoplay.esm.js";
import "@fancyapps/ui/dist/carousel/carousel.autoplay.css";
import { Link } from "react-router-dom";

function Fancybox(props) {
  const containerRef = useRef(null);
  const [isChanged, setIsChange] = useState();

  useEffect(() => {
    const container = document.getElementById("myCarousel");
    const options = {
      infinite: true,
      Autoplay: {
        timeout: 10000,
      },
      transition: "fade",
    };

    const carousel = new Carousel(container, options, { Autoplay });
    carousel.on("change", (currentSlide) => {
      setIsChange(currentSlide.page);
    });
    return () => {
      carousel.destroy();
    };
  }, []);

  const controls = useAnimation();

  useEffect(() => {
    const attentionAnimation = async () => {
      await controls.start({ opacity: 1 });
      await controls.start({
        scaleX: 1.2,
        scaleY: 1.2,
        transition: { duration: 0.5, repeatType: "reverse" },
      });
    };

    attentionAnimation();
  }, [controls]);
  return (
    <div
      ref={containerRef}
      options={{
        Carousel: {
          infinite: true,
        },
      }}
    >
      <div id="app">
        <div class="f-carousel f-carousel-img__container" id="myCarousel">
          <div class="f-carousel__slide" style={{ padding: 0 }}>
            <div className="carousel-caption">
              <main className={HomeCSS.hero_section}>
                <div className={HomeCSS.uniform_width}>
                  <div className={HomeCSS.part_1}>
                    <WelcomeMsg text="Welcome to DanRit" />
                    {!isChanged || isChanged === 0 ? (
                      <Title text="Discover & Book Beauty & wellness - professionals with ease" />
                    ) : (
                      <></>
                    )}

                    {!isChanged || isChanged === 0 ? (
                      <Info text="Indulge in pure relaxation with our expert masseurs at DanRit Salon's massage services." />
                    ) : (
                      <></>
                    )}

                    {!isChanged || isChanged === 0 ? (
                      <CallToAction text="Start Now " />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </main>
            </div>
            <img
              src={process.env.PUBLIC_URL + "/carouselImages/masseur.webp"}
              alt="masseur"
              // loading="lazy"
              className="image"
              // data-lazy-src={
              //   process.env.PUBLIC_URL + "/carouselImages/masseur.webp"
              // }
            />
          </div>
          <div class="f-carousel__slide" style={{ padding: 0 }}>
            <div className="carousel-caption">
              <main className={HomeCSS.hero_section}>
                <div className={HomeCSS.uniform_width}>
                  <div className={HomeCSS.part_1}>
                    <WelcomeMsg text="Welcome to DanRit" />

                    {/* ******************SECOND CONTENT************************ */}
                    {isChanged === 1 && (
                      <Title text="Discover & Book Beauty & wellness - professionals with ease" />
                    )}

                    {isChanged === 1 && (
                      <Info text="  Elevate your beauty with the artistry of makeup at DanRit Salon, where we create stunning looks just for you." />
                    )}
                    {!isChanged || isChanged === 1 ? (
                      <CallToAction text="Start Now " />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </main>
            </div>
            <img
              src={process.env.PUBLIC_URL + "/carouselImages/makeup.webp"}
              alt="makeup pic"
              // loading="lazy"
              className="image"
            />
          </div>
          <div class="f-carousel__slide" style={{ padding: 0 }}>
            <div className="carousel-caption">
              <main className={HomeCSS.hero_section}>
                <div className={HomeCSS.uniform_width}>
                  <div className={HomeCSS.part_1}>
                    <WelcomeMsg text="Welcome to DanRit" />

                    {/* **********************THIRD CONTENT ************************ */}
                    {isChanged === 2 && (
                      <Title text="Discover & Book Beauty & wellness - professionals with ease" />
                    )}

                    {isChanged === 2 && (
                      <Info text="  Danrit Hair Salon offers the best hair styles in all of Lanham, Maryland. We are a one stop beauty salon." />
                    )}
                    {!isChanged || isChanged === 2 ? (
                      <CallToAction text="Start Now " />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </main>
            </div>
            <img
              src={process.env.PUBLIC_URL + "/carouselImages/interior1.webp"}
              alt="interior1"
              // loading="lazy"
              className="image"
            />
          </div>
          {/* ============================================================== */}
          <div class="f-carousel__slide" style={{ padding: 0 }}>
            <div className="carousel-caption">
              <main className={HomeCSS.hero_section}>
                <div className={HomeCSS.uniform_width}>
                  <div className={HomeCSS.part_1}>
                    <WelcomeMsg text="Welcome to DanRit" />

                    {/* **********************THIRD CONTENT ************************ */}
                    {isChanged === 3 && (
                      <Title text="Discover & Book Beauty & wellness - professionals with ease" />
                    )}

                    {isChanged === 3 ? (
                      <Info text="  Transform your smile with our professional teeth whitening services at DanRit Salon.." />
                    ) : (
                      <></>
                    )}
                    {!isChanged || isChanged === 3 ? (
                      <CallToAction text="Start Now " />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </main>
            </div>
            <img
              src={
                process.env.PUBLIC_URL + "/carouselImages/teethwhitening.webp"
              }
              alt="image1"
              // loading="lazy"
              className="image"
            />
          </div>
          {/* ================================================================ */}
          <div class="f-carousel__slide" style={{ padding: 0 }}>
            <div className="carousel-caption">
              <main className={HomeCSS.hero_section}>
                <div className={HomeCSS.uniform_width}>
                  <div className={HomeCSS.part_1}>
                    <WelcomeMsg text="Welcome to DanRit" />

                    {/* ********************* FOURTH CONTENT************************** */}
                    {isChanged === 4 ? (
                      <Title text="Discover & Book Beauty & wellness - professionals with ease" />
                    ) : (
                      <></>
                    )}

                    {isChanged === 4 && (
                      <Info text="  DanRit Hair Salon offers the best hair styles in all of Lanham, Maryland. We are a one stop beauty salon." />
                    )}
                    {/* still testing this */}
                    {!isChanged || isChanged === 4 ? (
                      <CallToAction text="Start Now " />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </main>
            </div>

            <img
              src={process.env.PUBLIC_URL + "/carouselImages/makeup2.webp"}
              alt="image1"
              // loading="lazy"
              className="image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const Title = ({ text }) => {
  return (
    <>
      {/* <motion.div
      initial={{ x: "-60%" }}
      animate={{ x: 0 }}
      transition={{ duration: 1.2, ease: "easeOut" }}
      > */}
      <h1 className={HomeCSS.part1_h1}>{text}</h1>
      {/* </motion.div> */}
    </>
  );
};
const WelcomeMsg = ({ text }) => {
  return (
    // <motion.div
    //   className="box"
    //   initial={{ opacity: 1, scale: 1.01 }}
    //   animate={{ opacity: 1, scale: 1 }}
    //   whileTap={{ scale: 0.8 }}
    //   transition={{
    //     duration: 1.7,
    //     ease: "easeInOut",
    //     repeatType: "reverse",

    //     repeat: Infinity,
    //   }}
    // >
    <h3 className={HomeCSS.part1_h3}>
      <span className={HomeCSS.part1_span}></span>
      {text}
    </h3>
    // </motion.div>
  );
};
const Info = ({ text }) => {
  return (
    <>
      {/* <motion.div
      initial={{ x: "180%" }}
      animate={{ x: 0 }}
      transition={{ duration: 1.2, ease: "easeOut" }}
      > */}
      {/* Content inside the sliding element */}
      <p className={HomeCSS.part1_p}>{text}</p>
      {/* </motion.div> */}
    </>
  );
};
const CallToAction = ({ text }) => {
  return (
    <>
      {/* <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{
        opacity: 1,
        scale: 1,
        // boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
      }}
      transition={{
        duration: 1.2,
        ease: "easeOut",
        boxShadow: { duration: 0.3 },
      }}
    > */}
      <ul className={HomeCSS.hero_btn_container}>
        <Link to="/register">
          <button class="button__view-all" style={{ verticalAlign: "middle" }}>
            <span>{text} </span>
          </button>
        </Link>
      </ul>
      {/* </motion.div> */}
    </>
  );
};

export default Fancybox;
