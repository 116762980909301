import React, { Suspense, lazy } from "react";
// component imports
import Header from "./Components/Header/Header";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import ProtectedRoute from "./Components/Auth/AuthRoute";
import PersistLogin from "./Components/Auth/PersistLogin";
import LoadingScreen from "./Components/LoadingScreen/LoadingScreen";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

// import PayPalPayment from "./Components/Payment/PayPalPayment";

import { ErrorBoundary } from "./Components/ErrorBoundary/ErrorBoundary";
import Error500Page from "./Components/ErrorBoundary/Errors/Error500/Error500Page";
import {
  AuthContextProvider,
  useAuth,
} from "./Context/Auth/AuthContextProvider";
import Error404 from "./Pages/Errors/Error404";
// import Payment from "./Components/Payment/Payment";
import Makeup from "./Components/Makeup/Makeup";
import EmailConfirmation from "./Pages/EmailConfrimation/EmailConfirmation";
import Receipt from "./Pages/Receipt/Receipt";
import UserProfileView from "./Features/UserDashboard/UserProfileView";

import Brows from "./Components/Brows/Brows";
//brows  end
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { FacialService } from "./Components/Facials/FacialService";
import BodySculpting from "./Components/BodySculpting/BodySculpting";

import TeethWhitening from "./Components/TeethWhitening/TeethWhitening";
import PaymentOptions from "./Components/PaymentOption/PaymentOptions";
import Error401 from "./Pages/Errors/Error401";
import StripePayment from "./Components/Payment/Payment";
import PayPalPayment from "./Components/Payment/PayPalPayment";
import PayWithPayPal from "./Components/Payment/payWithPayPal";
import { SelectDurationContextProvider } from "./Context/Booking/SelectedDurationContext";
import { loadStripe } from "@stripe/stripe-js";

import GenerateReceipt from "./Pages/Receipt/GenerateReceipt";
import { HelmetProvider } from "react-helmet-async";
import QRCodePopUp from "./Components/HomeQRCode/QRCodePopUp";
import QuickLinks from "./Components/HomeQRCode/QuickLinks";
const { REACT_APP_STRIPE_PUB_KEY } = process.env;
const ResetPassword = lazy(() =>
  import("./Pages/Reset Password/ResetPassword")
);
const Services = lazy(() => import("./Pages/Services/Services"));
const About = lazy(() => import("./Pages/About/About"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const HairService = lazy(() => import("./Components/Hair"));
const UserDashboard = lazy(() =>
  import("./Features/UserDashboard/UserDashboard")
);
const Promo = lazy(()=> import("./Pages/Promo/Promo"));

const AdminDashboard = lazy(() =>
  import("./Features/AdminDashboard/src/AdminDashboard")
);

// =======================================|| COMPONENT ||========================================================
function App() {
  const initialOptions = {
    clientId: process.env.PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
      <Router>
        <Header />
        <></>
        <Routes>
          <Route element={<PersistLogin />}>
            <Route
              path="/"
              element={
                <HelmetProvider>
                  <Home />
                </HelmetProvider>
              }
            />
            <Route
              path="/reset-password/:token"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
                    {" "}
                    <ResetPassword />
                  </SnackbarProvider>
                </Suspense>
              }
            />
            <Route path="/load" element={<LoadingScreen />} />

            <Route
              path="/register"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SnackbarProvider
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    maxSnack={3}
                    autoHideDuration={3000}
                  >
                    <HelmetProvider>
                      <Register />{" "}
                    </HelmetProvider>
                  </SnackbarProvider>
                </Suspense>
              }
            />
            <Route
              path="/login"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={2500}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    {" "}
                    <HelmetProvider>
                      <Login />{" "}
                    </HelmetProvider>
                  </SnackbarProvider>
                </Suspense>
              }
            />
            <Route
              path="/error401"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <Error401 />{" "}
                </Suspense>
              }
            />
            <Route
              path="email-confirmation/:token"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
                    <EmailConfirmation />
                  </SnackbarProvider>
                </Suspense>
              }
            />
            <Route
              path="services"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <HelmetProvider>
                    <Services />
                  </HelmetProvider>
                </Suspense>
              }
            />
            {/* ======================= */}
            <Route
              path="services/hair"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
                    <HelmetProvider>
                      <HairService />
                    </HelmetProvider>
                  </SnackbarProvider>
                </Suspense>
              }
            />
            <Route
              path="services/facials"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <HelmetProvider>
                    <FacialService />
                  </HelmetProvider>
                </Suspense>
              }
            />
            <Route
              path="services/body-sculpting"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SelectDurationContextProvider>
                    <HelmetProvider>
                      <BodySculpting />
                    </HelmetProvider>
                  </SelectDurationContextProvider>
                </Suspense>
              }
            />
            <Route
              path="services/teeth-whitening"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SelectDurationContextProvider>
                    <HelmetProvider>
                      <TeethWhitening />
                    </HelmetProvider>
                  </SelectDurationContextProvider>
                </Suspense>
              }
            />
            <Route
              path="services/brow"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SelectDurationContextProvider>
                    <HelmetProvider>
                      <Brows />
                    </HelmetProvider>
                  </SelectDurationContextProvider>
                </Suspense>
              }
            />

            <Route
              path="services/makeup"
              makeup
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <HelmetProvider>
                    <Makeup />
                  </HelmetProvider>
                </Suspense>
              }
            />
            <Route
              path="about"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
                    <HelmetProvider>
                      <About />
                    </HelmetProvider>
                  </SnackbarProvider>
                </Suspense>
              }
            />
            <Route
              path="special-vip-offer"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
                    <HelmetProvider>
                      <Promo />
                    </HelmetProvider>
                  </SnackbarProvider>
                </Suspense>
              }
            />
            <Route
              path="privacy-policy"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path="/contact"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
                    <HelmetProvider>
                      <Contact />
                    </HelmetProvider>
                  </SnackbarProvider>
                </Suspense>
              }
            />
            <Route
              path="/quick-links"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <QuickLinks />
                </Suspense>
              }
            />
            <Route
              path="/spacial-offer/stripe/create-payment"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
                    <SelectDurationContextProvider>
                      <StripePayment />
                    </SelectDurationContextProvider>
                  </SnackbarProvider>
                </Suspense>
              }
            />

            <Route element={<ProtectedRoute requiredRoles={[0]} />}>
              <Route
                path="/payment-options/stripe/create-payment"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
                      <SelectDurationContextProvider>
                        <StripePayment />
                      </SelectDurationContextProvider>
                    </SnackbarProvider>
                  </Suspense>
                }
              />
              <Route
                path="/payment-options"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      maxSnack={3}
                      autoHideDuration={4000}
                    >
                      <SelectDurationContextProvider>
                        <PaymentOptions />{" "}
                      </SelectDurationContextProvider>
                    </SnackbarProvider>
                  </Suspense>
                }
              />
              <Route
                path="/receipt"
                element={
                  // <Elements stripe={stripePromise} options={options}>
                  <GenerateReceipt />
                }
              />
              <Route
                path="/customer/dashboard"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <UserDashboard />
                  </Suspense>
                }
              />
              <Route
                path="/customer/dashboard/profile"
                element={
                  <SnackbarProvider>
                    <UserProfileView />{" "}
                  </SnackbarProvider>
                }
              />
              <Route
                path="/payment-options/paypal/create-payment"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                      <SelectDurationContextProvider>
                        <PayWithPayPal />
                      </SelectDurationContextProvider>
                    </SnackbarProvider>
                  </Suspense>
                }
              />
            </Route>
            <Route element={<ProtectedRoute requiredRoles={[3, 1]} />}>
              <Route
                path="/admin/*"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <AdminDashboard />
                  </Suspense>
                }
              />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to="/404" replace />} />
          <Route path="/404" element={<Error404 />} />
        </Routes>
      </Router>
    </PayPalScriptProvider>
  );
}

export default App;
