import {
  Button,
  Checkbox,
  InputLabel,
  Select,
  TextField,
  OutlinedInput,
  Grid,
} from "@mui/material";
import styled from "@emotion/styled";
import { styled as muiStyled } from "@mui/material/styles";
import gifImage from "../../Assets/Images/danrit_vid3.gif"
export const StyledButton = styled(Button)`
  background: #fb464c;
  &:hover {
    background: #ff0000;
    color: #fff;
  }
`;
export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  // Default label styles
  color: "#8b7d7d",
  "&.Mui-focused": {
    color: "#8b7d7d", // change the color to primary color on focus
  },
}));

export const StyledOutlinedInputField = muiStyled(OutlinedInput)(
  ({ theme }) => ({
    "&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
      borderColor: "#8b7d7d", // set a custom color here
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e2dede",
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400],
    },
    "& .MuiInputLabel-root": {
      color: "#000", // set the label color here
    },
    "&.Mui-focused .MuiInputLabel-root": {
      color: "#000", // set the label color here
    },
  })
);
export const StyledSelectField = muiStyled(Select)(({ theme }) => ({
  "&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
    borderColor: "#8b7d7d", // set a custom color here
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#e2dede",
  },
  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grey[400],
  },
  "& .MuiInputLabel-root": {
    color: "#000", // set the label color here
  },
  "&.Mui-focused .MuiInputLabel-root": {
    color: "#000", // set the label color here
  },
}));
export const StyledInputField = muiStyled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: theme.palette.text.primary,
  },
  "& .MuiInputBase-input:focus": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiInputBase-input:hover": {
    borderColor: theme.palette.secondary.main,
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomColor: theme.palette.secondary.main,
  },
}));

export const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#ff0000", // replace with your custom
  padding: "1px",
  "&.Mui-checked": {
    color: "#ff0000", // replace with your custom color
  },
}));

export const StyledRegisterContainer = styled.div`
  // max-width: 450px;//
  width: 55%;
  // border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: #fff;
  flex-flow: row wrap;
  /* border: 1px solid blue; */
  padding-top: 2rem;
  padding: 0 1.5rem;
  .logo {
    width: 70px;
    height: 70px;
    border: 1px solid blue;
  }
  .signUpHeader {
    /* height: 100px; */
    width: 100%;
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 1rem; */
    padding: 1rem 0;
  }
  .signUpHeader div {
    text-align: center;
  }
  @media (max-width: 768px) {
    width: 450px;
  }
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    background-color: #ededed;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #ededed;
  }

  &::-webkit-scrollbar-thumb {
    height: 10px;

    border-radius: 10px;
    ${"" /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */}
    background-color: #bfbfbf;
  }
`;

export const StyledParentContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: calc(100vh - 68px);
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-height: 1000px) {
    height: 100vh;
  }
`;
export const StyledSideContainer = styled.div`
  width: 45%;
  height: 100vh;
  background-image: url(${gifImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #500101;
  background-blend-mode: overlay;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const StyledInnerSideContainer = styled.div`
  width: 80%;
  display: flex;
  align-content: center;
  flex-direction: column;
  height: 400px;
  // border: 1px solid blue;
  padding-right: 1rem;
  justify-content: space-around;
  @media (max-width: 768px) {
    height: 400px;
  }
  @media (min-height: 1000px) {
    height: 60vh;
  }
`;
export const StyledLogoContainer = styled.div`
  width: 70px;
  height: 70px;
  // border: 1px solid blue;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  color: #fff;
`;
export const StyledContentHeader = styled.div`
  width: 100%;
  // border: 1px solid yellow;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  color: #fff;
`;

export const StyledListItem = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 1rem;
  color: #fff;
  div {
    margin-right: 1rem;
  }
`;

export const CustomGridItem = styled(Grid)`
  height: 10px;
`;

export const StyledTermsContainer = styled.div`
  width: 80%;
  height: 30px;
  margin-top: 10%;
  color: #fff;
  a {
    color: #fff;
  }
`;
