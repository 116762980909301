import Receipt from "./Receipt";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import usePayment from "../../Hooks/usePayment";
import { Elements } from "@stripe/react-stripe-js";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import { SnackbarProvider } from "notistack";
const { REACT_APP_STRIPE_PUB_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUB_KEY);

function GenerateReceipt() {
  const [paymentDetails, setPaymentDetails] = usePayment();
  const [value, updateValue] = useLocalStorage("payment", {
    clientSecret: "",
  });

  const { clientSecret } = value;
  const appearance = {
    theme: "flat",
    variables: {
      borderRadius: "6px",
    },
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
      <Elements stripe={stripePromise} options={options}>
        <Receipt secrete={clientSecret} />
      </Elements>
    </SnackbarProvider>
  );
}

export default GenerateReceipt;
