import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  MenuItem,
  Menu,
  Avatar,
  Badge,
  ListItemIcon,
  Divider,
  Tooltip,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useAuth from "./../../../Hooks/useAuth";
import AccountPopover from "../../../Components/Header/AccountPopover";
import { BsFillPersonFill } from "react-icons/bs";
// import { Logout, Notifications } from "@mui/icons-material";
import MobileView from "../components/MobileView";
import DanritLogo from "../../../Assets/Images/Icons/danlogo.svg";
import styles from "../userDashboard.module.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import accountIcon from "../../../Assets/Images/Icons/profileIcon.svg";

const Header = () => {
  const { auth } = useAuth();
  const isMobileView = useMediaQuery("(max-width:1000px)");

  // styled MUI components
  const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "30px",
  });

  const UserBox = styled(Box)(({ theme }) => ({
    width: "30%",
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    gap: "15px",
    cursor: "pointer",
  }));

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#fafbfb",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;",
      }}
    >
      <StyledToolbar>
        {!isMobileView ? "" : <MobileView />}

        <Link to="/">
          <div>
            <img src={DanritLogo} alt="logo" width="60px" height="60px" />
          </div>
        </Link>

        {auth._id && (
          <div>
            {/* {!isMobileView ? (
              <motion.div
                whileHover={{
                  x: 5,
                  color: "#BA1607",
                }}
                whileTap={{ scale: 0.9 }}
              >
                <Link to="/">
                  <Typography
                    variant="body1"
                    color="#666362"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    gutterBottom
                  >
                    Home
                  </Typography>
                </Link>
              </motion.div>
            ) : (
              ""
            )} */}
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip>
                  <StyledToolbar>
                    {/* <UserBox>
                      <Avatar
                        sx={{
                          width: 40,
                          height: 40,
                          display: { xs: "none", md: "flex" },
                        }}
                      >
                        <BsFillPersonFill style={{ fontSize: "30px" }} />
                      </Avatar>
                      <AccountPopover />
                    </UserBox> */}

                    <div
                      style={{
                        width: "45px",
                        height: "45px",
                        background: "#eeeaea",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        fontWeight: "900",
                        fontSize: "1.2rem",
                        color: "#04297A",
                        fontFamily: "serif",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <span>
                        {/* {auth?.firstName.charAt(0) + "" + auth?.lastName.charAt(0)} */}
                        {/* <BsPersonCheck
                fontSize={fontSize ? fontSize : 35}
                color="#fb464c"
                fontWeight={900}
              /> */}
                        <AccountIcon />
                      </span>
                      {/* span */}
                    </div>
                    <h6
                      style={{
                        fontWeight: "bold",
                        color: "#666362",
                        fontSize: "1.2rem",
                        // marginLeft: "0.4rem",
                        // width: "100px",
                      }}
                    >
                      {auth &&
                        ` ${auth.firstName.charAt(0)}   ${auth.lastName.charAt(
                          0
                        )}`}
                    </h6>
                  </StyledToolbar>
                </Tooltip>
              </Box>
            </React.Fragment>
          </div>
        )}
      </StyledToolbar>
    </AppBar>
  );
};

const AccountIcon = () => {
  return (
    <div style={{ width: "50px", height: "50px" }}>
      <img src={accountIcon} alt="icon" />
    </div>
  );
};
export default Header;
