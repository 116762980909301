import React, { useState, useEffect } from "react";

import { MdOutlineKeyboardBackspace } from "react-icons/md";
import makeUpStyles from "../../Assets/data/makeup.json";
import "./makeup.scss";
import Footer from "../Footer/Footer";
import Booking from "./Booking";
import { motion } from "framer-motion";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import ServiceDetails from "../ServiceDetails/ServiceDetails";
import useAuth from "./../../Hooks/useAuth";
import FixedBG from "../FixedBG/FixedBG";

import { useNavigate } from "react-router-dom";
import { StyledCurve } from "../../Pages/Services/style";
import {
  MakeupContext,
  MakeupContextProvider,
} from "./../../Context/Booking/MakeupCalenderContextProvider";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
function Makeup() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubServiceIndex, setSelectedSubServiceIndex] = useState(null);
  const [showBooking, setShowBooking] = useState(false);
  const [hideOtherSubServices, setHideOtherSubServices] = useState(false); // New state for hiding other sub-services
  const navigate = useNavigate();
  const { auth } = useAuth();
  // const [serviceDetails, setServiceDetails] = useState({
  //   item: "",
  //   info: "",
  //   price: null,
  // });
  const [value, updateValue] = useLocalStorage("payment", {
    item: "",
    info: "",
    amount: null,
  });
  const handleCategorySelect = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(category);
    }
    setSelectedSubServiceIndex(null);
    setShowBooking(false);
    setHideOtherSubServices(false);
  };

  const handleBookingToggle = (index) => {
    if (selectedSubServiceIndex === index && showBooking) {
      setSelectedSubServiceIndex(null);
      setShowBooking(false);
      setHideOtherSubServices(false); // Unhide other sub-services
    } else {
      setSelectedSubServiceIndex(index);
      setShowBooking(true);
      setHideOtherSubServices(true); // Hide other sub-services
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is loaded
    window.scrollTo(0, 0);
  }, []);

  const StyledImage = styled.img``;

  return (
    <>
      <Helmet>
        <title>Makeup Services - DanRit Beauty Salon</title>
        <meta
          name="description"
          content="Enhance your beauty with professional makeup services at DanRit Beauty Salon. Get the perfect makeup look for any occasion."
        />

        <meta
          name="keywords"
          content="Bridal hair, Makeup, Special occasion styling"
        />
        <meta
          name="keywords"
          content="Prom Makeup, Bridal Makeup, Event Makeup, Wedding Makeup, Party Makeup, Special Occasion Makeup Professional Makeup,Makeup Artist, Makeup Services, Expert Makeup, Makeup Styling,Makeup Application"
        />
        <meta
          name="keywords"
          content="Natural Glam Look, Bold Glam Look, Fun Makeup, Sexy Glam Look, Quick Glam, Soft Glam, Simple Makeup Look, Eyes-Only Makeup Look"
        />
      </Helmet>
      <ServiceDetails
        serviceName={"Makeup Service"}
        pageLink={"/services"}
        pageName={"Services"}
        serviceText={
          "From subtle and natural to glamorous and striking, the makeup artists at DanRit Beauty Salon possess the expertise to craft a flawless and customized makeup look for any occasion."
        }
        image={
          "https://images.pexels.com/photos/5368636/pexels-photo-5368636.jpeg"
        }
      />

      <script>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '532804699038962');
          fbq('track', 'PageView');
          `}
      </script>
      <noscript>
        {`
          
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt="DanRit"
            src="https://www.facebook.com/tr?id=532804699038962&ev=PageView&noscript=1"
          />
          `}
      </noscript>

      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-EJ8VF2YNW0"
      ></script>

      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-EJ8VF2YNW0');
            `}
      </script>
      <FixedBG>
        <div className="pt-[50px]">
          <section className="max-w-[1200px] mx-auto px-5">
            <div className="flex flex-col">
              <button
                className="w-[fit-content] !block choose-category text-base items-start font-semibold text-paymentReceipt md:text-lg py-4 underline"
                onClick={() => handleCategorySelect(null)}
              >
                {selectedCategory ? (
                  <>&lt; View categories</>
                ) : (
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Choose a category
                  </span>
                )}
              </button>
              {makeUpStyles.map((makeup, index) => {
                const isCategorySelected = selectedCategory === makeup.category;
                const shouldRender = !selectedCategory || isCategorySelected;

                return (
                  shouldRender && (
                    <div key={index}>
                      {/* Main category card */}
                      <div className="main-card shadow-sm bg-white flex-col flex sm:flex-row sm:justify-between items-center my-5  rounded py-3 sm:p-4">
                        <div className="flex flex-col sm:flex-row items-center w-full">
                          {/* Main category image */}
                          <img
                            src={process.env.PUBLIC_URL + makeup.mainImage}
                            style={{
                              height: "40px",
                              width: "40px",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                            }}
                            alt="mainimages"
                            className="rounded-full bg-slate-300"
                          />

                          <div className="mx-8 category-card">
                            {/* Main category title */}
                            <h1 className="text-xs sm:text-xl text-black md:text-xl flex flex-row mb-4 sm:mb-0">
                              {makeup.category}
                            </h1>
                          </div>
                        </div>

                        {/* Button to toggle sub-services visibility */}
                        <button
                          id="select-btn"
                          className="bg-paymentReceipt mr-4 md:hover:bg-[#FF0000] !block border text-center rounded w-[50%] sm:w-[85px] text-white text-sm py-2 px-4"
                          onClick={() => handleCategorySelect(makeup.category)}
                        >
                          {isCategorySelected ? "Hide" : "Select"}
                        </button>
                      </div>

                      {/* Render sub-services when category is selected */}
                      {selectedCategory === makeup.category && (
                        <div className="subservice-card md:w-[98%] mx-auto md:flex md:flex-col">
                          {makeup.children.map((subService, index) => (
                            <motion.div
                              key={index}
                              initial={{ opacity: 0, scale: 0.5 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{
                                duration: 0.3,
                                delay: 0,
                                ease: "easeInOut",
                              }}
                              exit={{ opacity: 0, scale: 0 }}
                              className={`${
                                hideOtherSubServices &&
                                selectedSubServiceIndex !== index
                                  ? "hidden"
                                  : "subservice"
                              } flex flex-col my-5 sm:w-full justify-between rounded ${
                                selectedSubServiceIndex === index &&
                                hideOtherSubServices &&
                                "active"
                              }`}
                            >
                              <div className="sm:flex-row p-4 bg-white flex flex-col rounded">
                                {/* Sub-service image */}
                                {/* <div className="image__container"> */}
                                <img
                                  // style={{

                                  // }}
                                  src={
                                    process.env.PUBLIC_URL +
                                    subService.cardImage
                                  }
                                  alt="subservice-img"
                                  className=" mb-2 rounded makeup__img "
                                />
                                {/* </div> */}

                                <div className="flex flex-col gap-3 mt-3 sm:mt-0 items-start md:ml-5 sm:w-full justify-between ">
                                  {/* Sub-service title */}
                                  <h2 className="text-[16px]  font-semibold sm:text-[18px] md:text-[22px]  text-black pl-2 w-full">
                                    {subService.name}
                                  </h2>
                                  {/* Sub-service information for big screens */}
                                  <p className="pl-2 sm:text-base  sm:w-full">
                                    {subService.info}
                                  </p>
                                  {/* Sub-service duration */}
                                  <p className="pl-2 w-full flex flex-row text-[12px] sm:text-base sm:pl-2 font-bold">
                                    {subService.duration}&nbsp;
                                    <span className="flex flex-row ">
                                      @ {subService.cost}
                                    </span>
                                  </p>
                                </div>
                                <div className="flex flex-row mt-3 sm:mt-0 items-center px-2 justify-center sm:items-end md:justify-start">
                                  {/* Book now button */}
                                  <button
                                    className="bg-paymentReceipt md:hover:bg-[#FF0000] w-[50%] sm:w-[100px] border !block rounded text-white align-bottom  text-[10px] sm:text-[12px] py-2 px-2 sm:py-2 sm:px-4"
                                    onClick={() => {
                                      handleBookingToggle(index);

                                      updateValue({
                                        info: `${subService.info}\n ${subService.duration}`,
                                        item: subService.name,
                                        amount: subService.cost?.split("$")[1],
                                        serviceType: "Makeup",
                                        startingPrice: subService.cost
                                          ?.split("$")[1]
                                          .toString(),
                                      });
                                    }}
                                  >
                                    {selectedSubServiceIndex === index &&
                                    hideOtherSubServices
                                      ? "Cancel"
                                      : "Book Now"}
                                  </button>
                                </div>
                              </div>
                              {/* Render booking component */}
                              {selectedSubServiceIndex === index &&
                                hideOtherSubServices && (
                                  <motion.div
                                    key={index}
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      duration: 0.3,
                                      delay: 0,
                                      ease: "easeInOut",
                                    }}
                                    exit={{ opacity: 0, scale: 0 }}
                                    className="booking-wrapper"
                                  >
                                    <MakeupContextProvider>
                                      <Booking />
                                    </MakeupContextProvider>
                                  </motion.div>
                                )}
                            </motion.div>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </section>
          <StyledCurve>
            <div className="curve"></div>
          </StyledCurve>
        </div>
      </FixedBG>
      <Footer />
    </>
  );
}

// return <>{selectedCategory ? renderChildView() : renderMainView()}</>;

export default Makeup;
