import FlexImg from "../../Assets/about/FlexImg2.png";
import "./home_about.css";
import { Link } from "react-router-dom";
const HomeAbout = () => {
  return (
    <div className={`home_flex-container home_about-width custom-margin`}>
      <div className="home_flex-img">
        <img src={process.env.PUBLIC_URL + "/home_about.webp"} alt="" />
      </div>
      <div className="home_flex-content">
        <p className="home_flex-sub-title home_about-dark">
          Creating a peaceful Environment
        </p>
        <p className="home_flex-sub home_about-grey">
          Our primary focus is creating a peaceful environment where clients can
          escape from everyday stresses while indulging in personalized
          treatments designed specifically for them. Our commitment extends even
          beyond quality service delivery; we pride ourselves on staying ahead
          of new trends so that we can incorporate up to date technology into
          every service experience.
        </p>

        <Link to="/about">
          <button className="home_about-btn">About Us</button>
        </Link>
      </div>
    </div>
  );
};

export default HomeAbout;
