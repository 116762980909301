import { createContext, useState } from "react";

export const SelectDurationContext = createContext();

export const SelectDurationContextProvider = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);

  return (
    <SelectDurationContext.Provider
      value={{
        globalSelectedDate: [selectedDate, setSelectedDate],
        globalSelectedTime: [selectedTime, setSelectedTime],
      }}
    >
      {children}
    </SelectDurationContext.Provider>
  );
};
