import React, { useEffect } from "react";
import BodySculptingStyles from "../../Assets/data/bodysculpting.json";
import styled from "@emotion/styled";
import Wrapper from "../Layout/Wrapper";
import Footer from "../Footer/Footer";
import useAxiosSecured from "./../../Hooks/useAxiosSecured";
import { useState } from "react";
import { axiosInstance } from "../../Services/axiosInstance";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import backgroundImage from "../../Assets/Images/payment_options_bg.jpg";

import Booking from "./Booking";
import { BsContextProvider } from "../../Context/Booking/BsCalenderContextProvider";
import { extractAmount } from "../../Utils/helpers";

const StyledContainer = styled.div`
  // background-image: url(${backgroundImage});
  // background-size: cover;
  // // background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  padding-top: 2rem;
`;

const StyledCurve = styled.div`
  width: 100%;
  margin-top: 2rem;
  height: 30px;
  // border: 1px solid blue;
  border-top-right-radius: 26px;
  border-top-left-radius: 26px;
  background: #f2f5f9;
  .curve {
    width: 100%;
    height: 30px;

    // background-color: #f2f5f9;
    background-color: #131b20;
    width: 100%;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
  }
  @media screen and (max-width: 960px) {
    height: 20px;
    .curve {
      // background-color: #000;

      height: 20px;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
    }
  }
`;
const StyledImageContainer = styled.div`
  // width
`;

const StyledImage = styled.img`
  width: 160px;
  border-radius: 50% !important;
  height: 160px;
  margin: 0 auto;
  display: flex;
  align-self: center;
  background-image: url("${(props) => props.image}");
  background-size: cover;
  background-position: top;
  border-radius: 50% !important;
`;

function BodySculptingCards() {
  const axiosSecured = useAxiosSecured();
  const [spin, setSpin] = useState(false);
  const navigate = useNavigate();
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1); // Track the state of the Booking component
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const [value, updateValue] = useLocalStorage("payment", {
    item: "",
    info: "",
    amount: null,
  });
  useEffect(() => {
    setSpin(false);
  }, []);

  function addAmountToLocalStorage(data, amount) {
    updateValue({
      info: `${data.content}\n ${data.duration}`,
      item: data.category,
      amount: amount.toString(),
      serviceType: "Body Sculpting",
      startingPrice: amount.toString(),
    });
  }

  const handleOption1Change = (data, option) => {
    setIsChecked1(!isChecked1);
    if (!isChecked1) {
      const initialAmt =
        value.amount && value.amount !== "0" ? parseFloat(value.amount) : 0;
      let sumAmt =
        initialAmt + parseFloat(extractAmount(data[option]).split("$")[1]);

      addAmountToLocalStorage(data, sumAmt);
    } else {
      const initialAmt = value.amount ? parseFloat(value.amount) : 0;
      let sumAmt =
        initialAmt - parseFloat(extractAmount(data[option]).split("$")[1]);

      addAmountToLocalStorage(data, sumAmt);
    }
  };

  // Function to handle checkbox 2 change
  const handleOption2Change = (subService, option) => {
    setIsChecked2(!isChecked2);
    if (!isChecked2) {
      const initialAmt = value.amount ? parseFloat(value.amount) : 0;
      let sumAmt =
        initialAmt +
        parseFloat(extractAmount(subService[option]).split("$")[1]);
      addAmountToLocalStorage(subService, sumAmt);
    } else {
      const initialAmt =
        value.amount && value.amount !== "0" ? parseFloat(value.amount) : 0;
      let sumAmt =
        initialAmt -
        parseFloat(extractAmount(subService[option]).split("$")[1]);

      addAmountToLocalStorage(subService, sumAmt);
    }
    // handleCheckboxChange(subService, option);
  };
  const handleBookNowClick = (index) => {
    setSelectedItemIndex((prevIndex) => (prevIndex === index ? -1 : index)); // Toggle the value of selectedItemIndex

    // Hide all other item cards
    const itemCards = document.querySelectorAll(".item-card");
    itemCards.forEach((card, cardIndex) => {
      if (cardIndex !== index) {
        card.style.display = selectedItemIndex === index ? "flex" : "none";
      }
    });
  };

  return (
    <StyledContainer>
      <Wrapper>
        <div className="w-full flex flex-col">
          {BodySculptingStyles.map((data, index) => (
            <div>
              <div
                className="item-card sm:flex-row p-3 bg-white flex flex-col rounded mt-10"
                key={index}
              >
                {/* <StyledImage
                  src={process.env.PUBLIC_URL + data.mainImage}
                  alt="subservice-img"
                  className=" mb-2 rounded"

                /> */}
                <div
                  style={{
                    minWidth: "200px",
                    minHeight: "200px",
                    // border: "1px solid blue",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <StyledImage
                    role="img"
                    aria-label={"body sculpting"}
                    loading="lazy"
                    image={process.env.PUBLIC_URL + data.mainImage}
                    className=" mb-2 rounded w-100 h-300"
                  />
                </div>
                <div className="flex flex-col sm:px-4 items-start md:ml-5 sm:w-full justify-between ">
                  {/* Sub-service title */}
                  <h2 className="text-[16px] capitalize font-semibold sm:text-[18px] md:text-[22px] text-black pl-2 w-full">
                    {data.category}
                  </h2>
                  {/* Sub-service information for big screens */}
                  <p className="pl-2 sm:text-base sm:w-full">{data.content}</p>
                  {/* Sub-service duration */}
                  <p className="pl-2 w-full flex flex-row text-[12px] sm:text-base sm:pl-2 font-bold">
                    {data.duration}&nbsp;
                    <span className="flex flex-row  font-bold">
                      {data.price}
                    </span>
                  </p>
                </div>

                <div className="flex flex-row mt-3 sm:mt-0 items-center px-2 justify-center sm:items-end md:justify-start">
                  {/* Book now button */}
                  <button
                    className="bg-paymentReceipt md:hover:bg-[#FF0000] w-[50%] sm:w-[100px] border !block rounded text-white align-bottom  text-[10px] sm:text-[12px] py-2 px-2 sm:py-2 sm:px-4"
                    onClick={() => {
                      handleBookNowClick(index);

                      updateValue({
                        info: `${data.content}\n ${data.duration}`,
                        item: data.category,
                        amount: extractAmount(data.price)
                          ? extractAmount(data.price).split("$")[1]
                          : "",
                        serviceType: "Body Sculpting",
                      });
                    }} // Call handleBookNowClick when the button is clicked
                  >
                    {selectedItemIndex === index ? "Cancel" : "Book now"}
                  </button>
                </div>
              </div>
              {selectedItemIndex === index && (
                <BsContextProvider>
                  <div className="border-t border-gray-300 bg-white flex flex-wrap justify-around align-middle">
                    {data.option1 && (
                      <label className="text-black flex p-4 justify-center align-middle gap-2">
                        {data.option1}
                        <input
                          type="checkbox"
                          checked={isChecked1}
                          onChange={() => handleOption1Change(data, "option1")}
                        />
                      </label>
                    )}
                    {data.option2 && (
                      <label className="text-black flex p-4 justify-center align-middle gap-2">
                        {data.option2}
                        <input
                          type="checkbox"
                          checked={isChecked2}
                          onChange={() => handleOption2Change(data, "option2")}
                        />
                      </label>
                    )}
                    {data.medium && (
                      <label className="text-black flex p-4 justify-center align-middle gap-2">
                        {data.medium}
                        <input type="checkbox" />
                      </label>
                    )}
                    {data.small && (
                      <label className="text-black flex p-4 justify-center align-middle gap-2">
                        {data.small}
                        <input type="checkbox" />
                      </label>
                    )}
                  </div>
                  <Booking />
                </BsContextProvider>
              )}{" "}
              {/* Conditionally render the Booking component */}
            </div>
          ))}
        </div>
      </Wrapper>
      <StyledCurve>
        <div className="curve"></div>
      </StyledCurve>
      <Footer />
    </StyledContainer>
  );
}

export default BodySculptingCards;
