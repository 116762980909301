const style = {
  maxWidth: "1500px",
  width: "95%",
  display: "flex",
  flexWrap: "wrap",
  margin: "0 auto",
  justifyContent: "center",
};

const Wrapper = (props) => {
  return <div style={style}>{props.children}</div>;
};

export default Wrapper;
