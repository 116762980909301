import React from "react";
import BodySculptingCards from "./BodySculptingCards";
import ServiceDetails from "../ServiceDetails/ServiceDetails";
import FixedBG from "../FixedBG/FixedBG";
import { Helmet } from "react-helmet-async";

function BodySculpting() {
  return (
    <>
      <Helmet>
        <title>Body Sculpting Services - DanRit Beauty Salon</title>
        <meta
          name="description"
          content="Achieve your desired body shape and contours with professional body sculpting services at DanRit Beauty Salon."
        />

        <meta
          name="keywords"
          content="body sculpting, body contouring, body transformation, beauty salon services, fat reduction, non-surgical treatments,brazilian,bbl "
        />
        <meta
          name="keywords"
          content="Wood Therapy Packages, Wood Therapy for Cellulite, VIP Body Sculpting Session with Wood Therapy"
        />
        <meta name="keywords" content=" Spa, Massage, Relaxation" />
        <meta
          name="keywords"
          content="Swedish massage spa, Relaxation therapy, Spa day packages"
        />
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '532804699038962');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
          
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt="DanRit"
            src="https://www.facebook.com/tr?id=532804699038962&ev=PageView&noscript=1"
          />
          `}
        </noscript>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-EJ8VF2YNW0"
        ></script>

        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-EJ8VF2YNW0');
            `}
        </script>
      </Helmet>
      <ServiceDetails
        serviceName={"Body Sculpting Service"}
        pageLink={"/services"}
        pageName={"Services"}
        serviceText={
          "If you're looking to enhance your body's contours and achieve a more sculpted appearance, DanRit Beauty Salon offers innovative body sculpting treatments. Their non-invasive procedures, such as laser lipolysis, radiofrequency, or ultrasound therapy, target specific areas of stubborn fat, helping you achieve a toned and defined physique."
        }
        image={
          "https://images.pexels.com/photos/5042608/pexels-photo-5042608.jpeg"
        }
      />
      <FixedBG>
        <BodySculptingCards />
      </FixedBG>
    </>
  );
}

export default BodySculpting;
