import React from "react";
import styles from "./LoadingScreen.module.css";
function LoadingScreen() {
  return (
    <div>
      <div className={styles.loading_container}>
        <div className={styles.wrapper}>
          <span className={`${styles.circle} ${styles.circle_1}`}></span>
          <span className={`${styles.circle} ${styles.circle_2}`}></span>
          <span className={`${styles.circle} ${styles.circle_3}`}></span>
          <span className={`${styles.circle} ${styles.circle_4}`}></span>
          <span className={`${styles.circle} ${styles.circle_5}`}></span>
          <span className={`${styles.circle} ${styles.circle_6}`}></span>
          <span className={`${styles.circle} ${styles.circle_7}`}></span>
          <span className={`${styles.circle} ${styles.circle_8}`}></span>
          <br />
          <br />
          <br />
          {/* <h1>Loading... Please wait.This won't take long </h1> */}
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
