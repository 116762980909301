import * as React from "react";
import "./style.css";
import HomeCSS from "./style.module.css";
import Fancybox from "./Fancybox.jsx";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
// import { FancyBoxContext } from "./Fancybox";
export default function Slider(props) {
  return (
    <div>
      <Fancybox>{/* <ImageContainer /> */}</Fancybox>
    </div>
  );
}
