import React, { useContext, useEffect, useState } from "react";

import stripe from "../../images/stripe.svg";
import paypal from "../../images/paypal.svg";
import {
  StyledCard,
  StyledContainer,
  StyledInnerContainer,
  StyledButton,
  StyledParentContainer,
} from "./styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/material";
import AnimateButton from "../extended/AnimateButton";
import { TwContext } from "../../Context/Booking/TwCalenderContextProvider";
import { getStoredValue, useLocalStorage } from "../../Hooks/useLocalStorage";
import { useSnackbar } from "notistack";
const theme = createTheme({
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#ff",
          "&.Mui-checked": {
            color: "red",
          },
        },
      },
    },
  },
});
const PaymentOptions = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [selectedValue, setSelectedValue] = useState(null);
  const navigate = useNavigate();
  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };
  const [value, updateValue] = useLocalStorage("payment", {
    paymentMethod: "",
  });
  const [saveRoute, setSaveRoute] = useLocalStorage("saveRoute", {
    serviceRoute: "",
  });

  useEffect(() => {
    setSaveRoute({
      serviceRoute: "",
    });
  }, []);
  const handleRoute = () => {
    if (selectedValue) {
      updateValue({ paymentMethod: "stripe" });
      setTimeout(() => {
        navigate(`/payment-options/${selectedValue}/create-payment`);
      }, [200]);
    } else {
      enqueueSnackbar("Please choose a payment Option", { variant: "warning" });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledParentContainer>
        <StyledContainer>
          <CardComponent
            info="Starting Price"
            amount={`$${parseFloat(
              value.amount.replace("@", "").replace(/\s+/g, "")
            ).toFixed(2)}`}
          />
          <CardComponent
            info="Amount To pay(commitment Fee) "
            amount={`$${commitmentAmt(parseInt(value.amount)).toFixed(2)}`}
          />
          <CardComponent
            info="Select the Stripe option to pay with credit/Debit card. "
            amount=""
          />
          {/* </div> */}

          <StyledInnerContainer>
            <StyledCard>
              <div>
                <FormControlLabel
                  control={<Radio />}
                  label="Stripe"
                  value="stripe"
                  name="payment"
                  checked={selectedValue === "stripe"}
                  onChange={handleChange}
                />
              </div>
              <p>
                <img src={stripe} alt="stripe logo" />{" "}
              </p>
            </StyledCard>
            <StyledCard>
              <div>
                <FormControlLabel
                  control={<Radio disabled />}
                  label="Paypal (Coming Soon  )"
                  value="paypal"
                  name="payment"
                  checked={selectedValue === "paypal"}
                  onChange={handleChange}
                />
              </div>
              <p>
                <img src={paypal} alt="stripe paypal" />{" "}
              </p>
            </StyledCard>
          </StyledInnerContainer>
          <Box sx={{ mt: 2 }}>
            <AnimateButton>
              <StyledButton
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
                onClick={handleRoute}
                sx={{ textTransform: "capitalize" }}
              >
                Proceed
              </StyledButton>
            </AnimateButton>
          </Box>
        </StyledContainer>
      </StyledParentContainer>
    </ThemeProvider>
  );
};

const CardComponent = ({ info, amount }) => {
  return (
    <StyledCard>
      <p>{info}</p>
      <p>{amount}</p>
    </StyledCard>
  );
};

const commitmentAmt = (value) => {
  const value2 = 300;
  const tenPercent = value2 * 0.1;

  return tenPercent;
};

export default PaymentOptions;
