export const allItems = [
  { label: "Hair" },
  { label: "Brow" },
  { label: "Makeup" },
  { label: "Facials" },
  { label: "Teeth Whitening" },
  { label: "Body Sculpting" },
];

const [hair, brow, makeup, facials, teethWhitening, bodySculpting] = allItems;
export const initialTabs = [
  hair,
  brow,
  makeup,
  facials,
  teethWhitening,
  bodySculpting,
];

export function getNextIngredient(items) {
  const existing = new Set(items);
  return allItems.find((item) => !existing.has(item));
}
