import styled from "@emotion/styled";
import FacialsCalender from "./../Calender/FacialsCalender";
import { SnackbarProvider } from "notistack";
const StyledContainer = styled.div`
  padding: 1rem 1rem 0 1rem;
  background: #fff;
  border-top: 1px solid #b6cade;
  width: 100%;
  // border: 1px solid red;
  min-height: 400px;
  h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  p {
    text-align: center;
    font-size: bold;
    margin-bottom: 1rem;
  }
`;
const Booking = () => {
  return (
    <StyledContainer>
      <h2>Book Appointment</h2>
      <p>Note: to book for an early hour please contact us @ +1 240-906-1710</p>
      <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
        <FacialsCalender />
      </SnackbarProvider>
    </StyledContainer>
  );
};

export default Booking;
