import React, { useState } from "react";
// import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";

import {
  StyledButton,
  StyledContainer,
  StyledFormHeader,
  StyledInputField,
  StyledInputLabel,
  StyledLogoSpace,
} from "./styles";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import Swal from "sweetalert2"; // project imports
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
// component imports
import useScriptRef from "../../Hooks/useScriptRef";
import AnimateButton from "../../Components/extended/AnimateButton";
import { axiosInstance } from "../../Services/axiosInstance";
import useAuth from "../../Hooks/useAuth";
import useRefreshToken from "../../Hooks/useRefreshToken";
import SimpleBackdrop from "../../Components/LoadingScreen/BackDrop";
import loginBGD from "../../Assets/Images/login_bg_desktop.png";
import Logo from "../../Assets/Images/Icons/danlogo.svg";
function EmailConfirmation({ ...others }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const { setAuth, persist, setPersist } = useAuth();
  const scriptedRef = useScriptRef();
  const [checked, setChecked] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { token } = useParams();

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (values) => {
    const { email, password } = values;
    const data = {
      email,
      password,
    };
    handleOpen();

    axiosInstance
      .post(`/email-confirmation/${token}`, data)

      .then((response) => {
        handleClose();

        if (response.data.success) {
          const { role, is_verified, _id, email, firstName, lastName } =
            response.data.payload;
          const accessToken = response.data.payload.accessToken;
          const refreshToken = response.data.payload.newRefreshToken;

          // Cookies.set("jwt", refreshToken);
          setAuth({
            role,
            is_verified,
            accessToken,
            _id,
            email,
            firstName,
            lastName,
          });
          localStorage.setItem("persist", true);

          if (role === 3 || role === 1) return navigate("/admin/dashboard/app");

          if (role === 0 && is_verified) return navigate("/");
        }
      })
      .catch((error) => {
        handleClose();

        const message = error.response.data?.payload;
        enqueueSnackbar(message, { variant: "error" });
      });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${loginBGD})`,
      }}
    >
      <SimpleBackdrop
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        message="Please wait while we process your request. This may take a moment"
      />
      <StyledContainer>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <StyledFormHeader>
            <Box>
              <StyledLogoSpace>
                <h2>
                  <img src={Logo} alt="logo" width="70px" height="70px" />{" "}
                </h2>
              </StyledLogoSpace>
            </Box>

            <Box mb={2}>
              <Typography variant="h5">Confirm Your Account</Typography>
            </Box>
          </StyledFormHeader>
        </Grid>
        <Formik
          initialValues={{
            password: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .max(255)
              .matches(
                "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                {
                  message:
                    "Password Must Contain Minimum eight characters,at least one upper case,one lower case letter , one digit and  one special character. example:Password12#",
                }
              )
              .required("Password is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (scriptedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
              handleSubmit(values);
            } catch (err) {
              console.error(err);
              if (scriptedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              {...others}
              style={{ width: "100%" }}
            >
              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
                sx={{ ...theme.typography.customInput, marginBottom: "1rem" }}
              >
                <StyledInputLabel htmlFor="outlined-adornment-password-login">
                  Password
                </StyledInputLabel>
                <StyledInputField
                  id="outlined-adornment-password-login"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <MdOutlineVisibility />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  inputProps={{}}
                />
                {touched.password && errors.password && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-login"
                  >
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
              >
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  sx={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={async () => {
                    const { value: email } = await Swal.fire({
                      input: "email",
                      text: "Email Confirmation",
                      confirmButtonColor: "#9de0f6",
                      confirmButtonText: "Send",
                      inputAutoFocus: false,
                      backdrop: "static",
                      allowOutsideClick: false,
                      inputPlaceholder: "Type your email here...",
                      focusCancel: false,
                      focusConfirm: false,
                      inputAttributes: {
                        "aria-label": "Type your email here",
                      },
                      showCancelButton: true,
                    });
                    if (email) {
                      handleOpen();
                      axiosInstance
                        .post(
                          "/reset/password",

                          {
                            email,
                          },
                          { timeout: 50000 }
                        )

                        .then((response) => {
                          handleClose();
                          if (response.status == 200) {
                            Swal.fire({
                              title: "Sent!",
                              icon: "success",
                              backdrop: "static",
                              allowOutsideClick: false,
                              confirmButtonColor: "#9de0f6",
                              confirmButtonText: "OK",
                              focusCancel: false,
                              focusConfirm: false,
                              text: `A password reset email has been sent to your registered email address.`,
                            });
                          }
                        })
                        .catch((error) => {
                          handleClose();
                          const data = error.response.data;
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            backdrop: "static",
                            allowOutsideClick: false,
                            confirmButtonColor: "#9de0f6",
                            focusCancel: false,
                            focusConfirm: false,
                            text: data.payload,
                            // footer: '<a href="">Why do I have this issue?</a>'
                          });
                        });
                    }
                  }}
                >
                  Forgot Password?
                </Typography>
              </Stack>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Box sx={{ mt: 2 }}>
                <AnimateButton>
                  <StyledButton
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    // onClick={handleLogin}
                  >
                    Sign in
                  </StyledButton>
                </AnimateButton>
              </Box>
            </form>
          )}
        </Formik>
      </StyledContainer>
    </div>
  );
}

export default EmailConfirmation;
