import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";
// third party
import * as Yup from "yup";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { Scrollbar } from "react-scrollbars-custom";
// project imports
import Google from "../../Assets/Images/Icons/googleSocial.svg";
import useScriptRef from "../../Hooks/useScriptRef";
import AnimateButton from "../../Components/extended/AnimateButton";
import {
  strengthColor,
  strengthIndicator,
} from "../../Utils/password-strength";
import { axiosInstance } from "../../Services/axiosInstance";
import cities from "../../Assets/data/cities.json";
import {
  StyledButton,
  StyledInputField,
  StyledInputLabel,
  StyledOutlinedInputField,
  StyledRegisterContainer,
  StyledSelectField,
  CustomCheckbox,
  StyledSideContainer,
  StyledParentContainer,
  StyledInnerSideContainer,
  StyledLogoContainer,
  StyledContentHeader,
  StyledListItem,
  CustomGridItem,
  StyledTermsContainer,
} from "./styles";
import SimpleBackdrop from "../../Components/LoadingScreen/BackDrop";
import danRitLogo from "../../Assets/Images/Icons/danrit_logo.png";
import danRitMainLogo from "../../Assets/Images/Icons/danlogo.svg";
import logoDark from "../../Assets/Images/Icons/danlogow.svg";

import logoWhite from "../../Assets/Images/danlogowhite.png";
import { Helmet } from "react-helmet-async";

// ===========================||  REGISTER ||=========================== //
const textInfo = [
  "Open your account in seconds with full name email,location and password",
  "choose the service you want and book appointment",
  "Just pay a commitment fee to book a slot",
];

const Register = ({ ...others }) => {
  const theme = useTheme();
  const scriptedRef = useScriptRef();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  //   const customization = useSelector((state) => state.customization);
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const isScreenLarge = useMediaQuery("(min-height:1000px)");
  const isWidthLarge = useMediaQuery("(max-height:680px)");
  const isMobile = useMediaQuery("(max-width:769px)");
  const isMobileSmall = useMediaQuery("(max-width:768px)");

  // const phoneUSA = ^(?:\+1)?(?:\d{10}|\(\d{3}\)\s*\d{3}(?:[-\s]?)\d{4}|(?:\d{3}(?:[-\s]?)\d{3}(?:[-\s]?)\d{4}))$

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleRegistration = () => {
    // navigate("/login");
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp));
  };
  const handleSubmit = async ({
    firstName,
    lastName,
    email,
    password,
    phone,
    location,
  }) => {
    handleOpen();
    const data = { firstName, lastName, email, password, phone, location };
    axiosInstance
      .post("/register", data)
      .then((response) => {
        const data = response.data;
        handleClose();

        if (data.success) {
          // TODO: confirm email action
          Swal.fire({
            title: "Congratulations!",
            icon: "success",
            backdrop: "static",
            allowOutsideClick: false,
            confirmButtonColor: "#FD2629",
            confirmButtonText: "OK",
            text: `${data.payload}`,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
              document.getElementById(
                "outlined-adornment-firstName-register"
              ).value = "";
              document.getElementById(
                "outlined-adornment-lastName-register"
              ).value = "";
              document.getElementById(
                "outlined-adornment-phone-register"
              ).value = "";
              document.getElementById(
                "outlined-adornment-email-register"
              ).value = "";
              document.getElementById(
                "outlined-adornment-password-register"
              ).value = "";
              let selectElement = document.getElementById(
                "location-simple-select"
              );
              selectElement.selectedIndex = 0;
            }
          });
        } else {
          handleClose();
          enqueueSnackbar("Sorry Something went Wrong. Please try again", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        handleClose();
        // console.log(error);
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          const message = error.response.data?.payload;
          enqueueSnackbar(message, { variant: "warning" });
        } else if (error.request) {
          enqueueSnackbar(
            "Unable to connect to the server. Please check your internet connection and try again.",
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(
            "Sorry, we are unable to connect to your email.Please try again",
            { variant: "error" }
          );
        }
      });
  };

  useEffect(() => {
    changePassword("123456");
  }, []);

  return (
    <>
      <Helmet>
        <title>Registration - DanRit Beauty Salon</title>

        <meta
          name="description"
          content="Register for an account with DanRit Beauty Salon to access exclusive offers, appointments, and updates. Join our beauty community today!"
        />

        <meta
          name="keywords"
          content="registration, create an account, salon membership, beauty community, exclusive offers, appointments"
        />

        <meta name="author" content="DanRit Beauty Salon" />

        <link
          rel="canonical"
          href="https://www.danritbeautysalon.com/register"
        />

        <meta
          property="og:title"
          content="Registration - DanRit Beauty Salon"
        />
        <meta
          property="og:description"
          content="Register for an account with DanRit Beauty Salon to access exclusive offers, appointments, and updates. Join our beauty community today!"
        />
        <meta
          property="og:image"
          content="https://www.danritbeautysalon.com/static/media/danlogo.6bc6d612f55000b935b03dcae73a441a.svg"
        />
        <meta
          property="og:url"
          content="https://www.danritbeautysalon.com/register"
        />

        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '532804699038962');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
          
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt="DanRit"
            src="https://www.facebook.com/tr?id=532804699038962&ev=PageView&noscript=1"
          />
          `}
        </noscript>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-EJ8VF2YNW0"
        ></script>

        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-EJ8VF2YNW0');
            `}
        </script>
      </Helmet>
      <StyledParentContainer>
        <SimpleBackdrop
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
          message="Please wait while we process your request. This may take a moment"
        />
        <StyledSideContainer>
          <StyledInnerSideContainer>
            <div style={{ width: "100%" }}>
              <StyledLogoContainer>
                <img
                  src={logoDark}
                  width="100px"
                  height="100px"
                  alt="logo white"
                />
              </StyledLogoContainer>
            </div>
            <StyledContentHeader>
              <h2>Start your beauty journey with DanRit </h2>
            </StyledContentHeader>
            {textInfo.map((text) => (
              <ItemList text={text} />
            ))}
          </StyledInnerSideContainer>
          <StyledTermsContainer>
            <p>
              By signing up, you agree to our &nbsp;
              <AnimateText />
            </p>
          </StyledTermsContainer>
        </StyledSideContainer>
        <StyledRegisterContainer>
          <>
            <>
              <div className="signUpHeader">
                <img
                  src={danRitMainLogo}
                  width="100px"
                  height="100px"
                  alt="logo"
                />

                <div>
                  <Typography
                    variant="h4"
                    sx={{ color: "#2a2525" }}
                    fontWeight={900}
                  >
                    Sign up
                  </Typography>
                </div>
              </div>
            </>

            <CustomGridItem
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  color="#2a2525"
                  fontWeight={400}
                >
                  Sign up with email address
                </Typography>
              </Box>
            </CustomGridItem>
          </>

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              location: "",
              password: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .min(2)
                .max(200)
                .required("First Name is required!"),
              lastName: Yup.string()
                .min(2)
                .max(200)
                .required("Last Name is required!"),
              phone: Yup.string()
                .max(10)
                .matches(
                  /^(?:\+?1\s?)?(?:\(\d{3}\)|\d{3})(?:[-\s]?)?\d{3}(?:[-\s]?)?\d{4}$/,
                  {
                    message: "Phone number must not be more than 10 digits",
                  }
                )
                .required("Phone Number is required!"),
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              location: Yup.string().required("Location is required"),
              password: Yup.string()
                .max(255)
                .matches(
                  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                  {
                    message:
                      "Password Must Contain Minimum eight characters,at least one upper case,one lower case letter , one digit and  one special character. example:Password12#",
                  }
                )
                .required("Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                if (scriptedRef.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
                handleSubmit(values);
              } catch (err) {
                console.error(err);
                if (scriptedRef.current) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,

              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...others}>
                <Grid
                  container
                  spacing={matchDownSM ? 0 : 2}
                  sx={{
                    marginBottom: "1rem",
                    marginRight: "1rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12} sm={isScreenLarge ? 12 : 5.9}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.firstName && errors.firstName)}
                      sx={{
                        ...theme.typography.customInput,
                        marginBottom: "0.8rem",
                        mt: isScreenLarge ? 0 : 2,
                      }}
                    >
                      <StyledInputLabel htmlFor="outlined-adornment-firstName-register">
                        First Name
                      </StyledInputLabel>
                      <StyledOutlinedInputField
                        id="outlined-adornment-firstName-register"
                        type="text"
                        value={values.firstName}
                        label="first Name"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.firstName && errors.firstName && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text--register"
                        >
                          {errors.firstName}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={isScreenLarge ? 12 : 5.9}
                    sx={{ mt: isScreenLarge ? 0 : 2 }}
                  >
                    <FormControl
                      fullWidth
                      error={Boolean(touched.lastName && errors.lastName)}
                      sx={{
                        ...theme.typography.customInput,
                        marginBottom: "0.8rem",
                      }}
                    >
                      <StyledInputLabel htmlFor="outlined-adornment-lastName-register">
                        Last Name
                      </StyledInputLabel>
                      <StyledOutlinedInputField
                        id="outlined-adornment-lastName-register"
                        type="text"
                        value={values.lastName}
                        label="Last Name"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.lastName && errors.lastName && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text--register"
                        >
                          {errors.lastName}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={matchDownSM ? 0 : 2}
                  sx={{
                    marginBottom: "1rem",
                    marginRight: "1rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12} sm={isScreenLarge ? 12 : 5.9}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.phone && errors.phone)}
                      sx={{
                        ...theme.typography.customInput,
                        marginBottom: "0.8rem",
                      }}
                    >
                      <StyledInputLabel htmlFor="outlined-adornment-phone-register">
                        Phone Number
                      </StyledInputLabel>
                      <StyledOutlinedInputField
                        id="outlined-adornment-phone-register"
                        type="tel"
                        value={values.phone}
                        label="phone Number"
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Phone Number"
                        startAdornment={
                          <InputAdornment position="start">(+1)</InputAdornment>
                        }
                      />
                      {touched.phone && errors.phone && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text--register"
                        >
                          {errors.phone}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* ........................... */}
                  <Grid item xs={12} sm={isScreenLarge ? 12 : 5.9}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.location && errors.location)}
                      sx={{
                        ...theme.typography.customInput,
                        // marginBottom: "0.8rem",
                      }}
                    >
                      <StyledInputLabel id="location-simple-select-label">
                        Location
                      </StyledInputLabel>
                      <StyledSelectField
                        labelId="location-simple-select-label"
                        id="location-simple-select"
                        value={values.location}
                        label="Location"
                        name="location"
                        onChange={handleChange}
                      >
                        {cities.map((city) => (
                          <MenuItem key={city.abbreviation} value={city.name}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </StyledSelectField>
                      {touched.location && errors.location && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text--register"
                        >
                          {errors.location}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* ------------------ */}

                <Grid
                  container
                  spacing={matchDownSM ? 0 : 2}
                  sx={{
                    // marginBottom: "1rem",
                    marginRight: "1rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12} sm={isScreenLarge ? 12 : 5.9}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      sx={{
                        ...theme.typography.customInput,
                        marginBottom: "0.8rem",
                      }}
                    >
                      <StyledInputLabel htmlFor="outlined-adornment-email-register">
                        Email
                      </StyledInputLabel>
                      <StyledOutlinedInputField
                        id="outlined-adornment-email-register"
                        type="email"
                        value={values.email}
                        label="email"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.email && errors.email && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text--register"
                        >
                          {errors.email}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={isScreenLarge ? 12 : 5.9}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                      sx={{
                        ...theme.typography.customInput,
                        marginBottom: "1rem",
                      }}
                    >
                      <StyledInputLabel htmlFor="outlined-adornment-password-register">
                        Password
                      </StyledInputLabel>
                      <StyledOutlinedInputField
                        id="outlined-adornment-password-register"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        name="password"
                        label="Password"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          changePassword(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <MdOutlineVisibility />
                              ) : (
                                <AiOutlineEyeInvisible />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{}}
                      />
                      {touched.password && errors.password && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-password-register"
                        >
                          {errors.password}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {strength !== 0 && (
                    <FormControl fullWidth>
                      <Box sx={{ mb: 2, ml: 2 }}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Box
                              style={{ backgroundColor: level?.color }}
                              sx={{ width: 85, height: 8, borderRadius: "7px" }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle1" fontSize="0.75rem">
                              {level?.label}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </FormControl>
                  )}
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          checked={checked}
                          onChange={(event) => setChecked(event.target.checked)}
                          name="checked"
                          color="primary"
                          size="small"
                        />
                      }
                      label={
                        <Typography
                          fontSize="0.8rem"
                          fontWeight={550}
                          variant="span"
                          to="#"
                        >
                          Agree with Terms & Condition.
                        </Typography>
                      }
                    />

                    <Link
                      to="/privacy-policy"
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        textTransform: "capitalize",
                        fontSize: "0.8rem",
                      }}
                    >
                      Read...
                    </Link>
                  </Grid>
                </Grid>
                {/* </Scrollbar> */}
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box sx={{ mt: 2 }}>
                  <AnimateButton>
                    <StyledButton
                      disableElevation
                      // use isSubmitting to add button loader
                      disabled={!checked ? true : false}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      sx={{ marginBottom: "1rem" }}
                      onClick={handleRegistration}
                    >
                      Sign up
                    </StyledButton>
                  </AnimateButton>
                </Box>

                <Box alignSelf="center">
                  <Typography variant="subtitle1">
                    Have an account?{" "}
                    <Link
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        // textTransform: "lowercase",
                      }}
                      to="/login"
                    >
                      Login
                    </Link>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </StyledRegisterContainer>
      </StyledParentContainer>
    </>
  );
};

export default Register;

const ItemList = ({ text }) => {
  return (
    <>
      <StyledListItem>
        <div>
          {" "}
          <BsFillPatchCheckFill color="yellow" />{" "}
        </div>
        <p>{text}</p>
      </StyledListItem>
    </>
  );
};

export const AnimateText = ({ element }) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsEnlarged((prevIsEnlarged) => !prevIsEnlarged);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      initial={{ scale: 1 }}
      animate={{
        scale: [1, 1.02, 1.04, 1.02, 1],
      }}
      transition={{
        duration: 3,
        repeat: Infinity,
        repeatDelay: 1,
        ease: "easeInOut",
      }}
      style={{
        display: "inline-block",
      }}
    >
      <Link
        to="/privacy-policy"
        style={{
          color: "#FFFF00",
        }}
      >
        Terms and Policy{" "}
      </Link>
    </motion.div>
  );
};
