import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { axiosInstance, axiosSecured } from "../Services/axiosInstance";
import { useEffect } from "react";
import Cookies from "js-cookie";
const useAxiosSecured = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const requestIntercept = axiosSecured.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
        }
        const refreshToken = Cookies.get("jwt");
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosSecured.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevReq = error?.config;

        if (error?.response?.status === 403 && !prevReq?.sent) {
          prevReq.sent = true;

          const newAccessToken = await refresh();
          prevReq.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosSecured(prevReq);
        }

        return Promise.reject(error);
      }
    );
    return () => {
      axiosSecured.interceptors.request.eject(requestIntercept);
      axiosSecured.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosSecured;
};

export default useAxiosSecured;
