import { useState, useEffect } from "react";
import { ElfsightWidget } from "react-elfsight-widget";

function GoogleReviews() {
  const instaStyle = {
    display: "flex",
    maxWidth: "1100px",
    width: "80%",
    margin: "0 auto",
    paddingBottom: "50px",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    // { result },
    <div style={instaStyle}>
      <ElfsightWidget widgetID="460ca051-5e40-46dc-814d-a505633a3420" />
      {/* <div className="elfsight-app-2a9622ad-dd57-46d5-bf8b-40f4304df571"></div> */}
    </div>
  );
}

export default GoogleReviews;
