import React, { useEffect, useState } from "react";
import useAxiosSecured from "./useAxiosSecured";
import { axiosInstance } from "../Services/axiosInstance";
import { useLocalStorage } from "./useLocalStorage";

function usePayment() {
  const [paymentDetails, setPaymentDetails] = useState({
    clientSecret: null,
    item: "",
    info: "",
    amount: "",
    appointmentDate: null,
    appointmentTime: null,
  });
  const [value, setValue] = useLocalStorage("payment", {
    clientSecret: "",
    amount: "",
    itemId: "",
    info: "",
    appointmentDate: null,
    appointmentTime: null,
  });

  const axiosSecured = useAxiosSecured();

  useEffect(() => {
    // Retrieve the client secret from local storage on component load
    const {
      clientSecret,
      info,
      amount,
      itemId,
      appointmentDate,
      appointmentTime,
    } = value;
    if (clientSecret) {
      setPaymentDetails((prevPaymentDetails) => ({
        ...prevPaymentDetails,
        clientSecret: clientSecret,
        info,
        amount,
        itemId,
        appointmentDate,
        appointmentTime,
      }));
    }
  }, [value.clientSecret, value.info]); // Add value.clientSecret as a dependency

  const setPaymentDetailsAndLocalStorage = (updatedPaymentDetails) => {
    setPaymentDetails((prevPaymentDetails) => {
      const newPaymentDetails = {
        ...prevPaymentDetails,
        ...updatedPaymentDetails,
      };

      // Update specific properties in local storage object
      setValue((prevValue) => {
        const updatedValue = {
          ...prevValue,
          ...updatedPaymentDetails,
        };
        return {
          ...updatedValue,
          // Preserve existing properties if not updated
          // by copying from previous value
          clientSecret: updatedValue.clientSecret || prevValue.clientSecret,
          amount: updatedValue.amount || prevValue.amount,
          itemId: updatedValue.itemId || prevValue.itemId,
          info: updatedValue.info || prevValue.info,
          appointmentDate:
            updatedValue.appointmentDate || prevValue.appointmentDate,
          appointmentTime:
            updatedValue.appointmentTime || prevValue.appointmentTime,
        };
      });

      return newPaymentDetails;
    });
  };
  return [paymentDetails, setPaymentDetailsAndLocalStorage];
}

export default usePayment;
