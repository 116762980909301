import React from "react";
import QRCodePopUp from "./QRCodePopUp";
import bgImage from "../../Assets/Images/about-bg.jpg";
export default function QuickLinks() {
  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        height: "100vh",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

        // marginTop: "calc(100vh - 80px)",
      }}
    >
      <QRCodePopUp />

      {/* <h2>wewewewew</h2> */}
    </div>
  );
}
